import React from "react";
import { createPortal } from "react-dom";
import { RiCloseLine } from "react-icons/ri";
import WhiteContainer from "../WhiteContainer";
import withRouter from "../../hooks/withRouter";

const modalRoot = document.getElementById("modal-root");

function getScrollbarWidth() {
  const documentWidth = document.documentElement.clientWidth;
  const windowWidth = window.innerWidth;
  return windowWidth - documentWidth;
}

export function UIModalCloseButton({ className = "", onClick, size = 28 }) {
  return (
    <div className={`modal-close-button ${className}`} onClick={onClick}>
      <RiCloseLine size={size} className="bg-primary p-1.5 rounded-full" />
    </div>
  );
}

class UIModal extends React.Component {
  constructor(props) {
    super(props);
    const { name, containerClassName, closeOnContainerClick } = props;

    this.hash = "#" + name;
    this.el = document.createElement("div");
    this.el.id = name;
    this.el.className = "modal-container " + (containerClassName || "");

    // if (closeOnContainerClick) {
    //   this.el.addEventListener("click", this.onContainerClick);
    // }
  }

  close = () => {
    this.el.classList.add("exiting");
    document.body.style.overflow = "unset";
    document.body.style.paddingRight = "0";

    setTimeout(() => {
      this.el.remove();
    }, 200);
  };

  onKeyDown = (e) => {
    if (e.keyCode === 27) {
      e.preventDefault();
      e.stopPropagation();
      this.props.onRequestClose();
    }
  };

  onOutsideClick = (e) => {
    if (e.target === this.el) {
      this.close();
      this.props.onRequestClose();
    }
  };

  onContainerClick = (e) => {
    if (e.target === this.el) return;
    this.props.onRequestClose();
  };

  componentDidMount() {
    modalRoot.appendChild(this.el);

    document.body.style.paddingRight = getScrollbarWidth() + "px";
    document.body.style.overflow = "hidden";

    window.history.pushState({}, "", `#${this.props.name}`);

    window.addEventListener("keydown", this.onKeyDown);
  }

  componentDidUpdate() {
    if (window.location.hash !== this.hash) {
      this.props.onRequestClose();
    }

    this.el.className =
      "modal-container " + (this.props.containerClassName || "");
  }

  componentWillUnmount() {
    this.el.remove();
    // this.close();

    if (window.location.hash === this.hash) {
      // this.props.router.navigate(-1);
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    window.removeEventListener("keydown", this.onKeyDown);
    if (this.props.onContainerClick)
      this.el.removeEventListener("click", this.props.onContainerClick);

    // Restore scrolling when the component unmounts
    document.body.style.overflow = "";
    document.body.style.paddingRight = "";
  }

  render() {
    const { id = "", className = "", children } = this.props;
    return createPortal(
      <WhiteContainer
        id={id}
        className={`modal-body custom-scroll ${className}`}
        onClick={(e) => {
          e.stopPropagation();
          return false;
        }}
      >
        {children}
      </WhiteContainer>,
      this.el
    );
  }
}

export default withRouter(UIModal);
