import { useState, useEffect, useRef, useCallback } from "react";

export default function useOpenOnClick(elementId, closeOnAnyTap = false) {
  const [isOpen, setIsOpen] = useState(false);
  const isHiding = useRef(false);

  const close = useCallback(() => {
    setIsOpen((isOpen) => {
      if (isOpen) {
        const element = document.getElementById(elementId);
        element.classList.add("hiding");
        isHiding.current = true;

        setTimeout(() => {
          element.classList.remove("open");
          element.classList.remove("hiding");
          isHiding.current = false;
        }, 200);
      }
      return false;
    });
  }, [elementId, isHiding]);

  const open = useCallback(() => {
    setIsOpen((isOpen) => {
      if (!isOpen || !isHiding.current) {
        document.getElementById(elementId).classList.add("open");
      }
      return true;
    });
  }, [elementId, isHiding]);

  useEffect(() => {
    if (!isOpen) return;

    function onDocumentClick(e) {
      const element = document.getElementById(elementId);
      if (!element) {
        return;
      }
      if (closeOnAnyTap || !element.contains(e.target)) {
        const toaster = document.querySelector(".Toastify");
        // don't close if click on toast
        if (!toaster.contains(e.target)) {
          e.stopPropagation();
          e.preventDefault();
          close();
        }
      }
    }

    document.addEventListener("click", onDocumentClick, true);

    return () => document.removeEventListener("click", onDocumentClick, true);
  }, [elementId, isOpen, close]);

  return [open, close, isOpen];
}
