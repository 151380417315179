import React, { useState, useContext, useEffect } from "react";

import Navbar from "../../components/Navbar";
import usePrimaryBg from "../../hooks/usePrimaryBg";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";

import Footer from "../../components/Footer";

import useScrollToTop from "../../hooks/useScrollToTop";

/**
 * This wrapper resets the component state when the product changes
 */
export default function Privacy() {
  useScrollToTop();
  usePrimaryBg();

  useEffect(() => {
    // Get all paragraphs and add a classname
    const paragraphs = document.querySelectorAll("p");

    paragraphs.forEach((paragraph) => {
      paragraph.classList.add("text-darker-gray"); // Add your desired classname here
    });
  }, []); // Empty dependency array to run the effect once on component mount

  return (
    <>
      <Navbar />

      <div className="container mt-14">
        <h2 className="text-4xl font-bold mb-4">
          GDPR și Politica de Confidențialitate a Datelor pentru Market
          Structure FX
        </h2>

        <p>
          La{" "}
          <a href="https://www.marketstructurefx.ro/">
            www.marketstructurefx.ro
          </a>
          , luăm foarte în serios confidențialitatea utilizatorilor noștri.
          Această politică GDPR și de confidențialitate a datelor prezintă modul
          în care colectăm, folosim și stocăm datele personale ale
          utilizatorilor noștri.
        </p>

        <h3 className="mt-14 mb-4 font-bold text-3xl">Controlorul de Date</h3>

        <p>
          Controlorul de date pentru{" "}
          <a href="https://www.marketstructurefx.ro/">
            www.marketstructurefx.ro
          </a>{" "}
          este Market Structure FX. Ne puteți contacta la{" "}
          <a href="mailto:contact@marketstructurefx.ro">
            contact@marketstructurefx.ro
          </a>{" "}
          dacă aveți întrebări sau nelămuriri cu privire la datele dvs.
          personale.
        </p>

        <h3 className="mt-14 mb-4 font-bold text-3xl">
          Colectarea Datelor cu Caracter Personal
        </h3>

        <p>
          Colectăm date cu caracter personal de la utilizatorii noștri în
          următoarele moduri:
        </p>

        <ul>
          <p>
            Atunci când creați un cont pe site-ul nostru, colectăm numele,
            adresa de e-mail și parola dvs.
          </p>
          <p>
            Atunci când achiziționați unul dintre programele noastre
            educaționale, colectăm numele, adresa de e-mail, telefon, adresa de
            facturare și informațiile de plată.
          </p>
          <p>
            Atunci când ne contactați pentru asistență pentru clienți, colectăm
            numele dumneavoastră, adresa de e-mail și orice alte informații pe
            care alegeți să le furnizați.
          </p>
          <p>
            Atunci când utilizați site-ul nostru web, este posibil să colectăm
            anumite informații în mod automat, cum ar fi adresa IP și tipul de
            dispozitiv pe care îl utilizați.
          </p>
        </ul>

        <h3 className="mt-14 mb-4 font-bold text-3xl">
          Utilizarea Datelor cu Caracter Personal
        </h3>

        <p>
          Utilizăm datele cu caracter personal pe care le colectăm de la
          utilizatorii noștri în următoarele moduri:
        </p>

        <ul>
          <p>
            Pentru a furniza programele noastre educaționale și alte servicii
            utilizatorilor noștri.
          </p>
          <p>Pentru a procesa plățile și a îndeplini comenzile.</p>
          <p>
            Pentru a comunica cu utilizatorii noștri cu privire la conturile
            lor, comenzile și alte informații importante.
          </p>
          <p>
            Pentru a ne îmbunătăți site-ul și serviciile și pentru a efectua
            cercetări și analize.
          </p>
          <p>
            Pentru a respecta obligațiile legale și pentru a ne proteja
            drepturile noastre și ale utilizatorilor noștri.
          </p>
        </ul>

        <h3 className="mt-14 mb-4 font-bold text-3xl">
          Stocarea și Securitatea Datelor cu Caracter Personal
        </h3>

        <p>
          Stocăm datele cu caracter personal pe care le colectăm de la
          utilizatorii noștri pe servere securizate și luăm măsuri adecvate
          pentru a proteja aceste date împotriva accesului neautorizat,
          modificării, dezvăluirii sau distrugerii.
        </p>

        <p>
          Vom păstra datele dvs. cu caracter personal atât timp cât este necesar
          pentru a vă furniza serviciile noastre și pentru scopurile stabilite
          în această Politică privind GDPR și confidențialitatea datelor. În
          ciuda măsurilor luate pentru a proteja datele dvs. cu caracter
          personal, vă atragem atenţia că transmiterea informaţiilor prin
          Internet, în general, sau prin intermediul altor reţele publice, nu
          este complet sigură, existând riscul ca datele să fie văzute şi
          utilizate de către terţe părţi neautorizate. Nu putem fi responsabili
          pentru astfel de vulnerabilități ale unor sisteme care nu sunt sub
          controlul nostru.
        </p>

        <h3 className="mt-14 mb-4 font-bold text-3xl">
          Partajarea Datelor cu Caracter Personal
        </h3>

        <p>
          Putem partaja datele cu caracter personal cu terțe părți în
          următoarele circumstanțe:
        </p>

        <ul>
          <p>
            Cu furnizorii noștri de procesare a plăților, pentru a procesa
            plățile și a îndeplini comenzile.
          </p>
          <p>
            Cu furnizorii noștri de asistență pentru clienți, pentru a furniza
            servicii de asistență utilizatorilor noștri.
          </p>
          <p>
            Cu furnizorii noștri de găzduire și întreținere a site-ului web,
            pentru a furniza servicii de găzduire și întreținere a site-ului
            web.
          </p>
          <p>
            Cu consilierii noștri juridici și financiari, dacă este necesar
            pentru a respecta obligațiile legale sau pentru a ne proteja
            drepturile noastre și pe cele ale utilizatorilor noștri.
          </p>
          <p>
            Cu partenerii noștri de afaceri și afiliații noștri, dacă am obținut
            consimțământul dumneavoastră în acest sens.
          </p>
        </ul>

        <h3 className="mt-14 mb-4 font-bold text-3xl">Drepturile Dvs.</h3>

        <p>
          În calitate de utilizator al{" "}
          <a href="https://www.marketstructurefx.ro/">
            www.marketstructurefx.ro
          </a>
          , aveți următoarele drepturi în ceea ce privește datele dvs.
          personale:
        </p>

        <ul>
          <p>
            Dreptul de a accesa datele cu caracter personal pe care le deținem
            despre dvs.
          </p>
          <p>
            Dreptul de a ne solicita să corectăm sau să ștergem orice date cu
            caracter personal inexacte sau incomplete.
          </p>
          <p>
            Dreptul de a vă opune sau de a restricționa prelucrarea datelor dvs.
            personale.
          </p>
          <p>
            Dreptul de a ne solicita să vă furnizăm datele dvs. personale
            într-un format portabil sau să le transmitem unui alt operator.
          </p>
          <p>
            Dreptul de a vă retrage în orice moment consimțământul pentru
            prelucrarea datelor dumneavoastră cu caracter personal.
          </p>
        </ul>

        <h3 className="mt-14 mb-4 font-bold text-3xl">Plângeri</h3>

        <p>
          Aveți dreptul să depuneți o plângere la autoritatea de supraveghere cu
          privire la prelucrarea datelor dvs. cu caracter personal. În România,
          datele de contact ale autorității de supraveghere pentru protecția
          datelor sunt următoarele:
        </p>

        <p>
          Autoritatea Națională de Supraveghere a Prelucrării Datelor cu
          Caracter Personal
        </p>

        <p>
          B-dul G-ral. Gheorghe Magheru nr. 28-30, Sector 1, cod poștal 010336,
          București, Romania
        </p>

        <p>Telefon: +40.318.059.211 sau +40.318.059.212;</p>

        <p>
          E-mail:{" "}
          <a href="mailto:anspdcp@dataprotection.ro">
            anspdcp@dataprotection.ro
          </a>
        </p>

        <p>
          Fără a vă afecta dreptul dvs de a contacta în orice moment autoritatea
          de supraveghere, vă rugăm să ne contactați în prealabil, și vă
          promitem că vom depune toate eforturile necesare pentru a rezolva
          orice problemă pe cale amiabilă.
        </p>

        <h3 className="mt-14 mb-4 font-bold text-3xl">Cookie-uri</h3>

        <p>
          Utilizăm cookie-uri pe site-ul nostru web pentru a îmbunătăți
          experiența utilizatorului și pentru a analiza traficul de pe site-ul
          web. Prin utilizarea site-ului nostru web, sunteți de acord cu
          utilizarea cookie-urilor în conformitate cu acest GDPR și cu Politica
          de confidențialitate a datelor. Vă puteți gestiona preferințele
          privind cookie-urile prin ajustarea setărilor browserului dvs.
        </p>

        <p>
          <strong>Cum puteţi controla fişierele de tip cookie</strong>
        </p>

        <p>
          Dezactivarea şi refuzul de a primi cookie-uri îţi poate limita
          experienţa navigării pe internet, făcând anumite site-uri
          impracticabile sau dificil de vizitat. Însă alegerea este întotdeauna
          a utilizatorului.
        </p>

        <p>
          Vă puteți retrage consimțământul privind fișierele de tip cookie în
          orice moment. În acest scop, puteţi să blocaţi sau eliminaţi fişierele
          cookie fie prin intermediul setărilor browserului dvs. web sau prin
          utilizarea unor software-uri puse la dispoziţie de terți, sau urmând
          instrucţiunile specifice pentru categoriile de cookie din secţiunile
          următoare. In orice situaţie, pot apărea probleme legate de utilizarea
          anumitor părți ale site-ului web dacă dezactivaţi fişierele de tip
          cookie.
        </p>

        <p>
          În cazul în care doriți să eliminați fișierele de tip cookie stocate
          pe dispozitivele dvs. și să configurați browserul dvs. web astfel
          încât să refuze fișiere de tip cookie sau sa afiseze o notificare de
          avertizare inainte ca un cookie sa fie stocat pe calculatorul
          dumneavoastra, puteți să utilizați setările privind preferințele din
          browser-ul dvs. web. De obicei, puteți găsi setările de navigare
          referitoare la fișiere de tip cookie în meniurile „Opțiuni”,
          „Instrumente” sau „Preferințe” din browserul web. În funcție de
          browserele web existente, pot fi utilizate mijloace diverse pentru a
          dezactiva fișierele de tip cookie. Pentru a obține mai multe
          informații, vă rugăm să vizitați website-ul browserelor dvs. Daca nu
          cunoasteti tipul si versiunea de web browser pe care-l folositi,
          accesati meniul ‘Help’ in partea de sus a browserului, apoi ‘About’.
          Informatia relevanta va fi afisata.
        </p>

        <p>
          Puteți refuza utilizarea cookie-urilor Google Analytics descărcând și
          instalând Google Analytics Opt-out Browser Add-on.
        </p>

        <p>
          <a href="https://tools.google.com/dlpage/gaoptout">
            Google Analytics Opt-out Browser Add-on
          </a>
        </p>

        <p>
          Pentru setarile cookie-urilor generate de terți și pentru mai multe
          informații privind confidențialitatea legată de publicitatea online,
          IAB România pune la dispoziție următorul site:
        </p>

        <p>
          <a href="http://www.youronlinechoices.com/ro/">
            http://www.youronlinechoices.com/ro/
          </a>
        </p>

        <h3 className="mt-14 mb-4 font-bold text-3xl">Linkuri Utile</h3>

        <p>
          Dacă doriți să aflați mai multe informații despre cookie-uri, vă
          recomandăm următoarele linkuri:
        </p>

        <ul>
          <p>
            <a href="https://www.microsoft.com/ro-ro/privacystatement/Default.aspx">
              Microsoft Cookies guide
            </a>
          </p>
          <p>
            <a href="https://www.allaboutcookies.org/">All About Cookies</a>
          </p>
          <p>
            <a href="https://en.wikipedia.org/wiki/HTTP_cookie">Wikipedia</a>
          </p>
        </ul>

        <p>
          IAB România pune la dispoziție următorul site pentru a oferi mai multe
          informații privind confidențialitatea legată de publicitatea online:{" "}
          <a href="http://www.youronlinechoices.com/ro/">
            http://www.youronlinechoices.com/ro/
          </a>
        </p>

        <h3 className="mt-14 mb-4 font-bold text-3xl">
          Modificări ale Acestei Politici
        </h3>

        <p>
          Putem actualiza această Politică GDPR și de confidențialitate a
          datelor din când în când și ne vom notifica utilizatorii noștri cu
          privire la orice modificări materiale prin publicarea politicii
          actualizate pe site-ul nostru web.
        </p>

        <p>
          Utilizând{" "}
          <a href="https://www.marketstructurefx.ro/">
            www.marketstructurefx.ro
          </a>
          , sunteți de acord cu termenii acestei Politici privind GDPR și
          confidențialitatea datelor.
        </p>
      </div>

      <Footer />
    </>
  );
}
