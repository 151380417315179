import { useContext, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import * as Scroll from "react-scroll";

import {
  RiCloseLine,
  RiInformationLine,
  RiLogoutCircleRLine,
  RiSearchLine,
  RiStore2Line,
  RiUserLine,
} from "react-icons/ri";

import UIModal from "../../components/modals/UIModal";
import UserContext from "../../context/UserContext";

export default function SideMenu({ onRequestClose, openLoginPanel }) {
  const { user, logout } = useContext(UserContext);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  let ScrollLink = Scroll.Link;

  const navigateTo = (path) => {
    navigate(path);
  };

  const onUserLogout = () => {
    logout();
    navigate("/");
  };

  // Function to generate initials from first name and last name
  const generateInitials = (fullName) => {
    const names = fullName.split(" ");
    const firstName = names[0];
    const lastName = names[names.length - 1];
    return firstName.charAt(0) + lastName.charAt(0);
  };

  const urlPath = useLocation();

  return (
    <UIModal
      name="side-menu-nav"
      id="side-menu"
      onRequestClose={onRequestClose}
      closeOnContainerClick
    >
      <div className="flex justify-between items-center mb-6">
        <RiCloseLine
          className="text-white"
          size={24}
          onClick={onRequestClose}
        />
      </div>

      {/* My account */}
      {user ? (
        <div
          className="flex flex-col"
          onClick={() => navigate("/user/courses")}
        >
          <div className="flex items-center">
            <div className="w-10 h-10 bg-third rounded-full text-center flex justify-center items-center text-lg tracking-wide font-semibold">
              {generateInitials(user.fullName)}
            </div>
            <div className="ml-2 text-lg">{user.fullName}</div>
          </div>
          <hr className="border-gray my-4" />
          <Link
            to="/user/courses"
            className="p-2 mr-2 cursor-pointer transition-all hover:scale-110"
          >
            Cursurile mele
          </Link>
        </div>
      ) : (
        <div className="flex flex-col" onClick={openLoginPanel}>
          <div className="flex items-center">
            <div className="flex items-center justify-center w-11 h-11 rounded-full bg-secondary">
              <RiUserLine className="text-white" size={22} />
            </div>
            <div className="ml-2.5">Contul meu</div>
          </div>
        </div>
      )}

      {urlPath.pathname === "/" && (
        <div className="flex flex-col">
          <hr className="border-gray my-4" />
          <ScrollLink
            to="home"
            className="p-2 mr-2 cursor-pointer transition-all hover:scale-110"
            smooth={true}
            activeClass="nav-active"
            spy={true}
            offset={-100}
            onClick={onRequestClose}
          >
            Home
          </ScrollLink>
          <ScrollLink
            to="mentor"
            className="p-2 mr-2 cursor-pointer hover:scale-110 transition-all"
            smooth={true}
            activeClass="nav-active"
            spy={true}
            offset={0}
            onClick={onRequestClose}
          >
            Mentor
          </ScrollLink>
          <ScrollLink
            to="cursuri"
            className="p-2 mr-2 cursor-pointer hover:scale-110 transition-all"
            smooth={true}
            activeClass="nav-active"
            spy={true}
            offset={0}
            onClick={onRequestClose}
          >
            Cursuri
          </ScrollLink>
          <ScrollLink
            to="beneficii"
            className="p-2 mr-2 cursor-pointer hover:scale-110 transition-all"
            smooth={true}
            activeClass="nav-active"
            spy={true}
            offset={0}
            onClick={onRequestClose}
          >
            Beneficii
          </ScrollLink>
        </div>
      )}
      {urlPath.pathname.startsWith("/user/courses", "/check-out/") && (
        <Link to="/" className="mr-4 ml-2">
          Acasă
        </Link>
      )}
      <hr className="border-gray my-4" />
      {user ? (
        <div>
          <div className="flex" onClick={onUserLogout}>
            <RiLogoutCircleRLine size={22} className="mr-2 text-white" />
            <span className="">Deconectare</span>
          </div>
        </div>
      ) : (
        ""
      )}
    </UIModal>
  );
}

function PageLink({ children, Icon, to }) {
  return (
    <Link to={to}>
      <div className="flex items-center my-4">
        <Icon size={20} className="mr-3" />
        {children}
      </div>
    </Link>
  );
}
