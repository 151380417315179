import {
  RiUserLine,
  RiUserFill,
  RiShoppingBasketLine,
  RiShoppingBasketFill,
  RiListCheck2,
  RiStarLine,
  RiStarFill,
  RiMessage2Line,
  RiListUnordered,
} from "react-icons/ri";
import { NavLink } from "react-router-dom";

export const ADMIN_PAGE_LINKS = [
  {
    text: "Useri",
    path: "users",
  },
  {
    text: "Comenzi",
    path: "orders",
  },
  {
    text: "Coduri de reducere",
    path: "codes",
  },
];

function MenuButton({
  icon,
  text,
  onClick,
  isActive,
  className = "text-primary",
}) {
  return (
    <div
      className={`flex items-center py-3 px-2.5 rounded-md cursor-pointer ${className}`}
      onClick={onClick}
    >
      <div className={isActive ? "text-third" : "text-white"}>{icon}</div>
      <div className={`ml-2 ${isActive ? "text-third" : "text-white"}`}>
        {text}
      </div>
    </div>
  );
}

export default function AdminMenu() {
  const iconSize = 22;

  return (
    <div className="w-52 p-2">
      <NavLink to="users">
        {({ isActive }) => (
          <MenuButton
            icon={
              isActive ? (
                <RiUserFill size={iconSize} />
              ) : (
                <RiUserLine size={iconSize} />
              )
            }
            text="Useri"
            isActive={isActive}
          />
        )}
      </NavLink>

      <NavLink to="orders">
        {({ isActive }) => (
          <MenuButton
            icon={
              isActive ? (
                <RiListUnordered size={iconSize} />
              ) : (
                <RiListUnordered size={iconSize} />
              )
            }
            text="Comenzi"
            isActive={isActive}
          />
        )}
      </NavLink>

      <NavLink to="codes">
        {({ isActive }) => (
          <MenuButton
            icon={
              isActive ? (
                <RiStarFill size={iconSize - 1} />
              ) : (
                <RiStarLine size={iconSize - 1} />
              )
            }
            text="Coduri de reducere"
            isActive={isActive}
          />
        )}
      </NavLink>
    </div>
  );
}
