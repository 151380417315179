// import Button from "./Button";
import Logo from "../assets/images/logoFX.png";
import Logo2 from "../assets/images/logo_alb.png";
import { useState, useContext } from "react";
import * as Scroll from "react-scroll";
import { Link, useNavigate } from "react-router-dom";
import useOpenOnClick from "../hooks/useOpenOnClick";
import Button from "./Button";
import SideMenu from "./navbar/SideMenu";
import { RiMenu3Line, RiMenuLine } from "react-icons/ri";

import LoginPanel from "../components/login/LoginPanel";
import UserContext from "../context/UserContext";
import { useLocation } from "react-router-dom";

import UserDropdown from "./navbar/UserDropdown";

export default function Navbar() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [showLoginPanel, setShowLoginPanel] = useState(false);
  const [openUserPanel, closeUserPanel] = useOpenOnClick("user-dropdown");
  const [showSideMenu, setShowSideMenu] = useState(false);
  let ScrollLink = Scroll.Link;

  const navigateTo = (path) => {
    closeUserPanel();
    navigate(path);
  };

  const openLoginPanelFromSideNav = () => {
    setShowSideMenu(false);
    setTimeout(() => setShowLoginPanel(true), 200);
  };

  const closeSideMenu = () => {
    setShowSideMenu(false);
  };

  const urlPath = useLocation();

  return (
    <header className="bg-primary w-full stick sticky top-0 z-[99] shadow-2xl">
      {showLoginPanel && (
        <LoginPanel onRequestClose={() => setShowLoginPanel(false)} />
      )}
      <nav className="container flex justify-between relative navbar">
        <div className="w-full flex items-center justify-between p-4">
          <Link to="/" className="">
            <img src={Logo} className="max-w-xs hidden xl:flex" alt="Logo" />
            <img src={Logo2} className="max-w-[80px]  xl:hidden" alt="Logo" />
          </Link>
          <RiMenuLine
            size={24}
            className="lg:hidden mr-5 popover-parent"
            onClick={() => setShowSideMenu(true)}
          />
          {/* Side menu */}
          {showSideMenu && (
            <SideMenu
              onRequestClose={() => setShowSideMenu(false)}
              openLoginPanel={() => openLoginPanelFromSideNav()}
            />
          )}

          <div className="items-center text-center hidden lg:flex">
            <div className="">
              {urlPath.pathname === "/" && (
                <>
                  <ScrollLink
                    to="home"
                    className="p-2 mr-2 cursor-pointer transition-all hover:scale-110"
                    smooth={true}
                    activeClass="nav-active"
                    spy={true}
                    offset={-100}
                  >
                    Home
                  </ScrollLink>

                  <ScrollLink
                    to="mentor"
                    className="p-2 mr-2 cursor-pointer hover:scale-110 transition-all"
                    smooth={true}
                    activeClass="nav-active"
                    spy={true}
                    offset={0}
                  >
                    Mentor
                  </ScrollLink>
                  <ScrollLink
                    to="cursuri"
                    className="p-2 mr-2 cursor-pointer hover:scale-110 transition-all"
                    smooth={true}
                    activeClass="nav-active"
                    spy={true}
                    offset={0}
                  >
                    Cursuri
                  </ScrollLink>
                  <ScrollLink
                    to="beneficii"
                    className="p-2 mr-2 cursor-pointer hover:scale-110 transition-all"
                    smooth={true}
                    activeClass="nav-active"
                    spy={true}
                    offset={0}
                  >
                    Beneficii
                  </ScrollLink>
                </>
              )}

              {urlPath.pathname.startsWith("/user/courses", "/check-out/") && (
                <Link to="/" className="mr-4">
                  Acasă
                </Link>
              )}
            </div>

            {/* <Button
              text="Cumpara acum"
              containerClassName="mr-2 cursor-pointer"
            /> */}
            {/* <Link to="/user/courses">Contul meu</Link> */}
            <UserDropdown
              openLoginPanel={() => setShowLoginPanel(true)}
              openUserPanel={openUserPanel}
              closePanels={closeUserPanel}
              navigateTo={navigateTo}
            />
          </div>
        </div>
      </nav>
    </header>
  );
}
