import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function usePrimaryBg() {
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add("bg-primary");
    return () => {
      document.body.classList.remove("bg-primary");
    };
  }, [location]);
}
