import { Outlet, useLocation } from "react-router-dom";
import usePrimaryBg from "../../hooks/usePrimaryBg";
import useScrollToTop from "../../hooks/useScrollToTop";
import { useContext, useState } from "react";
import useOutletTitle from "../../hooks/useOutletTitle";
import UserPanel from "../../components/UserPanel";
import UserContainer from "../../components/UserContainer";
import CourseCard from "../../components/CourseCard";

export default function OwnProfile() {
  useOutletTitle("Profil");
  //   const { user, setUser } = useContext(UserContext);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [canSaveExtraCost, setCanSaveExtraCost] = useState(false);

  //   const { data: productsSummary } = useSWR(
  //     isArtist(user) ? `/users/${user._id}/products-summary` : null,
  //     get
  //   );

  // const { data: stats } = useSWRImmutable(isArtist(user) ? `/users/${user._id}/stats` : null, get);

  return (
    <>
      <UserContainer className=""></UserContainer>
    </>
  );
}
