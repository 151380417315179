import { useLayoutEffect, useRef, useEffect } from "react";
import {
  RiArrowDownSLine,
  RiArrowDropDownLine,
  RiSearchLine,
  RiInformationFill,
  RiLock2Fill,
} from "react-icons/ri";
import useOpenOnClick from "../hooks/useOpenOnClick";

export function InputText({
  label,
  type = "text",
  name = "",
  onChange,
  value,
  defaultValue,
  placeHolder,
  className = "",
  containerClassName = "",
  labelClassName = "",
  autoCapitalize = "off",
  autoComplete,
  required,
  info,
  max,
  min,
  disabled,
}) {
  return (
    <div className={`w-full mb-4 ${containerClassName}`}>
      {label && (
        <div
          className={`flex items-center mb-1.5 font-semibold ${labelClassName}`}
        >
          {label}
          {info && (
            <div className="tooltip-trigger">
              <div className="tooltip-text">{info}</div>
              <RiInformationFill size={18} className="text-[#ccc] ml-2" />
            </div>
          )}
        </div>
      )}
      <div className="relative flex items-center">
        <input
          placeholder={placeHolder}
          type={type}
          name={name}
          className={`input-field ${className}`}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          spellCheck="false"
          autoCapitalize={autoCapitalize}
          autoComplete={autoComplete}
          required={required}
          max={max}
          min={min}
          disabled={disabled}
        />
        {disabled && (
          <RiLock2Fill className="absolute right-3 text-light-secondary" />
        )}
      </div>
    </div>
  );
}

export function InputTextArea({
  label,
  type,
  name = "",
  onChange,
  onBlur,
  value,
  defaultValue,
  placeHolder,
  id = "",
  className = "",
  containerClassName = "",
  labelClassName,
  rows = 1,
  minRows = 1,
  autoResizeHeight = false,
  autoCapitalize = "off",
  forwardedRef,
}) {
  const ref = useRef();

  useEffect(() => {
    if (value === undefined && autoResizeHeight) {
      console.warn("Auto resize height does not work on uncontrolled inputs");
    }
  }, []);

  useLayoutEffect(() => {
    if (autoResizeHeight) {
      autoAdjustHeight(ref.current);
    }
  }, [value]);

  useLayoutEffect(() => {
    if (ref.current && autoAdjustHeight) {
      const adjustHeight = () => {
        autoAdjustHeight(ref.current);
      };
      window.addEventListener("resize", adjustHeight);
      return () => window.removeEventListener("resize", adjustHeight);
    }
  }, [ref.current]);

  return (
    <div className={`w-full mb-4 ${containerClassName}`}>
      {label && (
        <p className={`mb-1.5 font-semibold ${labelClassName}`}>{label}</p>
      )}
      <textarea
        id={id}
        placeholder={placeHolder}
        type={type}
        name={name}
        className={`input-field text-area ${className}`}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        defaultValue={defaultValue}
        spellCheck="false"
        rows={autoResizeHeight ? minRows : rows}
        autoCapitalize={autoCapitalize}
        ref={ref}
      />
    </div>
  );
}

export function InputSearch({
  containerClassName = "",
  className = "",
  placeHolder,
  onChange,
  value,
  onSubmit,
}) {
  const handleEnter =
    onSubmit &&
    ((e) => {
      if (e.key === "Enter") onSubmit();
    });

  return (
    <div className={`search-field ${containerClassName}`}>
      <input
        placeholder={placeHolder}
        type="search"
        className={`input-field bg-transparent ${className}`}
        onChange={onChange}
        value={value}
        onKeyDown={handleEnter}
      />
      <RiSearchLine
        size={18}
        className="search-field-icon text-light-secondary"
        onClick={onSubmit}
      />
    </div>
  );
}

export function InputSelect({
  id,
  name,
  onSelect,
  selectedOption,
  options,
  optionRenderer,
  label,
  className = "",
  labelClassName = "",
  containerClassName = "",
  optionsContainerClassName = "",
  optionClassName = "",
  placeHolder,
  size = "normal", // normal or small
}) {
  const [open, close, isOpen] = useOpenOnClick(id);
  const rotateArrow = isOpen ? "rotate-180" : "";
  const textSize = size === "small" ? "text-md" : "";

  let padding = "pl-3 pr-1.5 py-2";
  let arrow = (
    <RiArrowDownSLine size={20} className={`${rotateArrow} shrink-0`} />
  );

  if (size === "small") {
    padding = "pl-3 pr-0.5 py-0.5";
    arrow = <RiArrowDropDownLine size={20} className={`${rotateArrow}`} />;
  }

  const select = (e, o) => {
    e.stopPropagation();
    onSelect(o);
    close();
  };

  return (
    <>
      <div className={`w-full mb-4 ${containerClassName}`}>
        {label && (
          <p className={`mb-1.5 font-semibold ${labelClassName}`}>{label}</p>
        )}

        <div
          onClick={open}
          className={`input-select ${textSize} ${padding} ${className}`}
        >
          <div className="mt-0.5 overflow-hidden overflow-ellipsis whitespace-nowrap">
            {selectedOption || placeHolder}
          </div>

          {arrow}

          <div
            id={id}
            className={`popover select-popover ${optionsContainerClassName}`}
          >
            {options.map((o) =>
              optionRenderer ? (
                optionRenderer(o, o === selectedOption, select)
              ) : (
                <p
                  key={o}
                  className={`select-option ${
                    o === selectedOption ? "selected" : ""
                  } ${optionClassName}`}
                  onClick={(e) => select(e, o)}
                >
                  {o}
                </p>
              )
            )}
          </div>
        </div>
      </div>

      <input type="select" hidden name={name} value={selectedOption} readOnly />
    </>
  );
}

export function CheckBox({
  label,
  name,
  className,
  checked,
  onChange,
  defaultChecked,
}) {
  const id = `cb-${label}}`;
  return (
    <div className={`select-none pl-5 ${className}`}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        name={name}
        defaultChecked={defaultChecked}
        className="-ml-5"
      />
      <label htmlFor={id} className="ml-2">
        {label}
      </label>
    </div>
  );
}

export function RadioBox({
  label,
  className = "",
  value,
  checked,
  name,
  onChange,
  onCheck,
  ...restProps
}) {
  const id = useRef(`rb-${label}-${Math.random().toString(32).slice(2)}`);

  const onChangeProxy = (e) => {
    if (e.target.checked && onCheck) {
      onCheck(e);
    }
    if (onChange) onChange(e);
  };

  return (
    <div className={`select-none ${className}`}>
      <input
        id={id.current}
        type="radio"
        value={value}
        checked={checked}
        onChange={onChangeProxy}
        name={name}
        {...restProps}
      />
      {label && (
        <label htmlFor={id.current} className="cursor-pointer ml-1.5">
          {label}
        </label>
      )}
    </div>
  );
}

function autoAdjustHeight(element) {
  if (!element) return;
  const style = window.getComputedStyle(element);
  const extra =
    parseInt(style.getPropertyValue("border-bottom")) +
    parseInt(style.getPropertyValue("border-top"));
  element.style.height = "auto";
  element.style.overflowY = "hidden";
  const height = element.scrollHeight + extra;
  element.style.height = `${height}px`;
}
