import useIsMobile from "../hooks/useIsMobile";
import { useRef, useState, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";

export default function Collapsible({
  title,
  titleNumber,
  children,
  isProductDetails,
  containerClassName = "",
  headerClassName = "",
  bodyClassName = "",
  contentClassName = "",
  openByDefault = false,
  autoAdjustHeight = false,
}) {
  const containerRef = useRef();
  const contentRef = useRef();
  const bodyRef = useRef();
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);
  const [seeMoreButtons, setSeeMoreButtons] = useState(false);
  const [isFullHeight, setIsFullHeight] = useState(isMobile);

  const close = () => {
    if (!containerRef.current) return;
    bodyRef.current.style.maxHeight = 0;
  };

  const open = () => {
    if (!contentRef.current) return;

    if (isProductDetails) {
      if (!isFullHeight) {
        bodyRef.current.style.maxHeight = "300px";
      } else {
        bodyRef.current.style.maxHeight = `${
          contentRef.current.clientHeight + 30
        }px`;
      }
    } else {
      bodyRef.current.style.maxHeight = `${contentRef.current.clientHeight}px`;
    }
  };

  const toggle = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
    setIsOpen(!isOpen);
  };

  // open by default
  useEffect(() => {
    if (openByDefault) {
      toggle();
    }
  }, [openByDefault]);

  // check if it's productDetails and content is higher than 300px
  useEffect(() => {
    if (isProductDetails && contentRef.current.clientHeight > 300) {
      setSeeMoreButtons(true);
    }
  }, []);

  useEffect(() => {
    if (autoAdjustHeight && isOpen) {
      open();
    }
  }, [children]);

  return (
    <>
      <div className="relative">
        <div ref={containerRef} className={`collapsible ${containerClassName}`}>
          <div
            className={`collapsible__header ${headerClassName}`}
            onClick={toggle}
          >
            <div className="flex">
              <p className="mr-1.5 whitespace-nowrap">{titleNumber}</p>
              <p className="font-bold">{title}</p>
            </div>

            <FaChevronDown
              size={14}
              className={`mt-0.5 ml-2 transition-transform duration-200 ${
                isOpen ? "rotate-180" : ""
              }`}
            />
          </div>

          <div
            ref={bodyRef}
            className={`collapsible__body relative ${bodyClassName}`}
          >
            <div className={`p-5 ${contentClassName}`} ref={contentRef}>
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
