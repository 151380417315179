export default function WhiteContainer({
  id = "",
  className = "",
  children,
  forwardRef,
  onClick,
}) {
  return (
    <div
      id={id}
      ref={forwardRef}
      className={`bg-white rounded-lg elevated-sm ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
