import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import * as userApi from "../api/userApi";

const UserContext = React.createContext();
export default UserContext;

export function UserProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [checkingSession, setCheckingSession] = useState(true);

  const login = async (userData, includeWelcomeMessage = false) => {
    if (!userData) return;

    if (userData.error) {
      return toast.error(userData.error);
    }

    setUser(userData);
    setIsLoggedIn(true);

    if (includeWelcomeMessage) {
      toast.success("Bine ai venit!", {
        autoClose: 1500,
      });
    }
  };

  const logout = async (userData) => {
    await userApi.logoutUser();
    setUser(null);
    setIsLoggedIn(false);
  };

  const checkSession = async (includeWelcomeMessage = false) => {
    setCheckingSession(true);
    const res = await userApi.checkLoginStatus(); //Check the structure of 'res' here

    if (!res || !res.user) return null;

    login(res.user, includeWelcomeMessage);
    setCheckingSession(false);
    return res.user;
  };

  // Check if user is logged in on app load
  useEffect(() => {
    checkSession(false).then(() => {
      setCheckingSession(false);
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        user,
        checkingSession,
        checkSession,
        logout,
        login,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
