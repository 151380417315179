import { Outlet, useLocation } from "react-router-dom";
import usePrimaryBg from "../../hooks/usePrimaryBg";
import useScrollToTop from "../../hooks/useScrollToTop";
import { useContext, useState, useEffect } from "react";
import useOutletTitle from "../../hooks/useOutletTitle";
import UserPanel from "../../components/UserPanel";
import UserContainer from "../../components/UserContainer";
import CourseCard from "../../components/CourseCard";
import UserContext from "../../context/UserContext";
import { toast } from "react-toastify";
import { getUserAccess } from "../../api/userApi";

export default function UserCourses() {
  useOutletTitle("Cursurile");

  const { user, checkSession } = useContext(UserContext);

  const [hasCourse, setHasCourse] = useState(false);
  const [hasScalping, setHasScalping] = useState(false);

  useEffect(() => {
    async function fetchUserAccess() {
      try {
        const res = await getUserAccess(user); // Replace 'user' with the appropriate user ID or data

        if (res.error) {
          toast.error(res.error);
        } else {
          // Set the hasRequiredPlans state based on the response
          setHasCourse(res.course);
          setHasScalping(res.scalping);
        }
      } catch (error) {
        console.error("Error fetching user access:", error);
        toast.error("An error occurred while fetching user access.");
      }
    }

    fetchUserAccess();
  }, [user]);

  return (
    <UserContainer className="">
      {hasCourse && (
        <CourseCard
          title={"Market Structure FX Mentorat Privat"}
          goTo="standard"
        />
      )}
      {hasScalping && <CourseCard title={"Scalping XAUUSD"} goTo="scalping" />}
      {!hasCourse && !hasScalping && (
        <span>Nu ai achiziționat niciun curs.</span>
      )}
    </UserContainer>
  );
}
