import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

const VideoPlayer = ({ videoSource }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      // Initialize Video.js when the component mounts
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        sources: [
          {
            src: videoSource,
            type: "video/mp4", // mp4
          },
          {
            src: videoSource,
            type: "video/x-matroska", // MKV format
          },
        ],
      });

      return () => {
        // Clean up Video.js when the component unmounts
        if (playerRef.current) {
          playerRef.current.dispose();
        }
      };
    }
  }, [videoSource]);

  return (
    <div data-vjs-player>
      {/* Display a loading indicator while the video is loading */}

      <video
        ref={videoRef}
        className={`video-js vjs-default-skin`}
        preload="auto" // Preload the video
      />
    </div>
  );
};

export default VideoPlayer;
