import React, { useState, useContext, useEffect } from "react";

import Navbar from "../../components/Navbar";
import usePrimaryBg from "../../hooks/usePrimaryBg";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";

import Footer from "../../components/Footer";

import useScrollToTop from "../../hooks/useScrollToTop";

/**
 * This wrapper resets the component state when the product changes
 */
export default function Terms() {
  useScrollToTop();
  usePrimaryBg();

  useEffect(() => {
    // Get all paragraphs and add a classname
    const paragraphs = document.querySelectorAll("p");

    paragraphs.forEach((paragraph) => {
      paragraph.classList.add("text-darker-gray"); // Add your desired classname here
    });
  }, []); // Empty dependency array to run the effect once on component mount

  useEffect(() => {
    // Get all paragraphs and add a classname
    const paragraphs = document.querySelectorAll("h2");

    paragraphs.forEach((paragraph) => {
      paragraph.classList.add("mt-14", "mb-4", "font-bold", "text-3xl"); // Add your desired classname here
    });
  }, []); // Empty dependency array to run the effect once on component mount

  return (
    <>
      <Navbar />

      <div className="container mt-14">
        <h1 className="text-4xl font-bold mb-12">
          <strong>Termeni și condiții pentru marketstructurefx.ro</strong>
        </h1>
        <p>
          Acești termeni și condiții ("Termenii") se aplică tuturor
          utilizatorilor site-ului marketstructurefx.ro ("Site-ul"). Prin
          utilizarea site-ului web, sunteți de acord cu acești Termeni. Dacă nu
          sunteți de acord cu acești Termeni, nu ar trebui să utilizați site-ul
          web.
        </p>
        <h2>
          <strong>Cine suntem?</strong>
        </h2>
        <p>
          Suntem Market Structure FX S.R.L., persoană juridică română, cu sediul
          social în Bucuresti Sectorul 6, Splaiul INDEPENDENTEI, Nr. 202B,
          camera 42, înregistrată la Registrul Comerțului sub nr.
          J40/16003/2023, având CUI 48694624, e-mail:{" "}
          <a href="mailto:contact@marketstructurefx.ro">
            contact@marketstructurefx.ro
          </a>{" "}
          .
        </p>
        <h2>
          <strong>Legislație aplicabilă și jurisdicție</strong>
        </h2>
        <p>
          Prezentul contract, Termenii și Condițiile prezentate, precum și
          întregul conținut al acestora, sunt guvernate și interpretate în
          conformitate cu legea română în vigoare.
        </p>
        <p>
          Prezentele Termeni și Condiții, precum și conținutul acestora,
          respectă legislația în materie și regulile privind protecția
          consumatorului, sens în care au fost redactate prin luarea în
          considerare a Legii nr. 365/2002 privind comerțul electronic, a Legii
          nr. 296/2004 privind Codul consumului, a Ordonanței Guvernului nr.
          21/1992 privind protecția consumatorilor, a Ordonanței de Urgență a
          Guvernului nr. 34/2014 privind drepturile consumatorilor în cadrul
          contractelor încheiate cu profesioniștii, precum și pentru modificarea
          și completarea unor acte normative, precum și Regulamentul GDPR nr.
          2016/679 privind protecția datelor cu caracter personal.
        </p>
        <p>
          Părțile convin ca eventualele neînțelegeri sau litigii apărute în
          legătură cu acordul încheiat, să fie soluționate pe cale amiabilă.
        </p>
        <p>
          În măsura în care rezolvarea diferendelor pe cale amiabilă nu este
          posibilă, litigiile care ar putea rezulta din orice procedură,
          neînțelegere sau solicitare a Părților și/sau care pot apărea din
          relația dintre Părți, contractuală sau de altă natură, vor fi
          înaintate spre soluționare instanțelor de judecată române competente.
        </p>
        <h2>
          <strong>Comanda și încheierea contractului</strong>
        </h2>
        <p>
          Datele și informațiile furnizate în pagina de prezentare a Produselor
          nu constituie o ofertă din partea Market Structure FX, ci o invitație
          la ofertă.
        </p>
        <p>
          Comanda poate fi plasată numai online, prin selectarea de către
          Utilizator a Produsului pe care dorește să îl achiziționeze. Ulterior
          selectării Produsului dorit, Comanda va fi plasată prin intermediul
          paginii de comandă, unde vor fi furnizate următoarele informații:
        </p>
        <p>
          a) datele comenzii: nume, prenume, adresa, telefon, adresa de e-mail
        </p>
        <p>
          b) metoda de plată: prin selectarea uneia dintre variantele indicate
          și completarea datelor aferente cardului bancar.
        </p>
        <p>
          Prin plasarea Comenzii, Utilizatorul confirmă că toate datele
          furnizate sunt corecte, complete și reale. În cazul achiziționării
          Produselor digitale, Utilizatorul își va crea un cont cu parolă pentru
          accesarea acestora.
        </p>
        <p>
          În situația în care, din orice motive, comanda nu poate fi procesată,
          acest fapt va fi adus la cunoștința Utilizatorului în cel mai scurt
          timp.
        </p>
        <p>
          Ne rezervăm dreptul discreționar absolut și fără a fi necesar să
          oferim un motiv, să refuzăm acceptarea unei oferte de achiziționare a
          unuia sau mai multor Produse. În asemenea situații, între părți nu se
          va încheia niciun contract și nu vom avea nicio răspundere.
        </p>
        <p>
          Ne rezervăm dreptul de a retrage sau de a bloca, în orice moment,
          produsele promovate pe site spre achiziționare și/sau orice alte
          informații referitoare la acestea.
        </p>
        <p>
          Ne rezervăm dreptul de a anula o Comandă plasată, în cazul existenței
          unor motive justificate, dintre care putem menționa:
        </p>
        <p>– suspiciunea de fraudă;</p>
        <p>– neefectuarea plății;</p>
        <p>
          – neacceptarea Tranzacției de către banca emitentă a cardului
          Utilizatorului;
        </p>
        <p>– invalidarea Tranzacției de către procesatorul de carduri;</p>
        <p>
          – furnizarea de către Utilizator a unor date incomplete sau eronate;
        </p>
        <p>
          – suspiciunea rezonabilă a Market Structure FX că Utilizatorul
          urmărește un scop ilicit sau că poate produce orice fel de prejudicii
          Market Structure FX sau afiliaților ori colaboratorilor acestuia;
        </p>
        <p>
          – nerespectarea întocmai a Termenilor și Condițiilor din prezentul
          document.
        </p>
        <p>
          Contractul dintre părți se va încheia în momentul în care plata este
          efectuată de către Utilizator prin intermediul mijloacelor electronice
          de plată, înțelegându-se prin aceasta momentul în care contul Market
          Structure FX a fost creditat cu suma achitată de Utilizator.
        </p>
        <p>
          Orice sume de bani încasate din plăți realizate în baza unor comenzi
          care nu au putut fi procesate sau onorate, din orice motive, vor fi
          rambursate de către Market Structure FX Utilizatorului.
        </p>
        <h2>
          <strong>Utilizarea site-ului web</strong>
        </h2>{" "}
        <p>
          Puteți utiliza site-ul web numai pentru uz personal și necomercial. Nu
          puteți utiliza site-ul web în niciun alt scop fără consimțământul
          nostru expres în scris.
        </p>
        <p>
          Includerea Site-ului nostru pe alte site-uri web este permisă numai cu
          acordul expres, în scris și prealabil al Market Structure FX. Market
          Structure FX poate include linkuri către alte site-uri pe Site-ul său
          web. Cu toate acestea, cu excepția situațiilor în care este specificat
          în mod expres altfel, site-urile la care se face trimitere nu sunt sub
          controlul și responsabilitatea marketstructurefx.ro. <br />
          Market Structure FX nu își asumă și nu acceptă nicio responsabilitate
          pentru conținutul site-urilor terțe la care poate face trimitere în
          anumite momente. Includerea unui link către un alt site web pe Site-ul
          nostru are un scop informativ și nu implică susținere din partea
          noastră a respectivelor site-uri sau a persoanelor care le
          administrează.
        </p>
        <h2>
          <strong>Proprietatea intelectuală</strong>
        </h2>
        <p>
          Conținutul de pe site-ul web, inclusiv, dar fără a se limita la text,
          grafică, logo-uri, imagini și software, este proprietatea Market
          Structure FX și este protejat de drepturile de autor, mărci comerciale
          și alte legi privind proprietatea intelectuală. Nu aveți voie să
          copiați, modificați, reproduceți, distribuiți sau afișați niciun
          conținut de pe site-ul web fără consimțământul nostru expres în scris.
          Conținutul documentelor noastre, al videoclipurilor și al serverului
          comunitar sunt toate lucrări originale și vor rămâne proprietatea
          Market Structure FX.
        </p>
        <h2>
          <strong>Programe educaționale</strong>
        </h2>
        <p>
          Site-ul web oferă programe educaționale ("Programe") care sunt
          destinate exclusiv scopurilor educaționale. Nu garantăm niciun
          rezultat anume în urma utilizării Programelor noastre. Nu trebuie să
          tranzacționați sau să investiți numai pe baza acestor informații.
          Vizualizând orice material sau folosind informațiile din serverul
          comunității noastre, sunteți de acord că acestea sunt materiale de
          educație generală și nu veți putea atrage răspunderea nici unei
          persoane sau entitate pentru pierderi sau daune rezultate din
          conținutul sau sfaturile generale furnizate de Market Structure FX.
        </p>
        <p>
          Market Structure FX a depus toate diligențele necesare pentru a
          realiza produse complete, corecte și care să cuprindă informații reale
          și exacte în ceea ce privește aspectele financiare și economice
          prezentate în cuprinsul acestora. Cu toate acestea, Market Structure
          FX nu își asumă niciun fel de declarație, promisiune sau garanție cu
          privire la exactitatea, corectitudinea sau exhaustivitatea
          conținutului produselor și nici vreo obligație de rezultat sau de
          diligență în ceea ce privește materialele, documentele și/sau
          conținutul acestora și exclude în mod expres răspunderea pentru orice
          cost, pierdere sau daună suferită ca urmare a utilizării produselor,
          precum și orice deziderat ca produsul furnizat să satisfacă pe deplin
          nevoile fiecărui Utilizator.
        </p>
        <p>
          Market Structure FX nu își asumă responsabilitatea pentru nicio
          persoană care acționează în mod propriu ca urmare a informațiilor
          cuprinse în cadrul produselor furnizate sau al opiniilor exprimate în
          cursurile prezentate ori în cadrul ședințelor de coaching și nu poate
          fi ținut responsabil pentru niciuna și orice pagubă care ar putea să
          fie suferită de un Utilizator prin folosirea produselor achiziționate,
          scopul produselor furnizate fiind adus la cunoștință de Market
          Structure FX.
        </p>
        <h2>
          <strong>Plată și rambursări</strong>
        </h2>
        <p>
          Sunteți de acord să plătiți taxele pentru Programele pe care le
          achiziționați de pe Site-ul web. Toate taxele sunt nerambursabile, cu
          excepția cazurilor în care acest lucru este cerut de lege sau dacă se
          specifică altfel pe Site-ul web.
          <br></br>
          <br></br>
          Prețul pentru toate produsele furnizate va fi achitat de Utilizator în
          avans în ceea ce privește achiziția produselor digitale.
        </p>
        <p>
          Market Structure FX își rezervă dreptul să modifice sau să actualizeze
          în orice moment prețul produselor, o astfel de actualizare înlocuind
          orice alt preț afișat anterior pentru respectivul produs.
        </p>
        <p>
          În situația în care prețul sau orice alte detalii referitoare la
          produse au fost afișate, din orice motiv, în mod eronat în prezentare,
          Market Structure FX va informa prin e-mail Utilizatorul care a plasat
          o comandă în aceste condiții, în cel mai scurt timp de la momentul
          constatării erorii.
        </p>
        <p>
          Market Structure FX nu solicită plăți suplimentare, dar nu este și nu
          poate fi ținut responsabil pentru niciun cost suplimentar suportat de
          Utilizator față de prețul produsului achiziționat, incluzând, dar fără
          a se limita la, comisioane de transfer sau de conversie valutară,
          aplicate de către banca emitentă a cardului Utilizatorului, în
          situația în care moneda de emitere a cardului este diferită de moneda
          în care este efectuată vânzarea.
        </p>
        <p>
          Plata produselor se poate efectua prin ordin de plată sau prin plată
          cu cardul bancar de credit sau de debit, la momentul plasării comenzii
          online, prin intermediul procesatorului de plăți Stripe. În cazul
          efectuării plății cu cardul bancar, tranzacția va apărea pe extrasul
          de cont cu denumirea Market Structure FX.
        </p>
        <p>
          Plata online se efectuează în condiții de siguranță deplină, cu cardul
          personal sau al companiei cumpărătoare, iar cardurile acceptate la
          plată sunt cele emise sub siglele VISA (Classic și Electron) și
          MASTERCARD (inclusiv Maestro, dacă au cod CVV2/CVC2).<br></br>
          <br></br>
          Procesarea datelor de pe cardul bancar este efectuată în mod exclusiv
          pe serverele Stripe. Siguranța informațiilor este garantată de faptul
          că Stripe trimite în mod criptat datele confidențiale ale cardului
          dumneavoastră către banca procesatoare, prin intermediul unei
          conexiuni securizate.
        </p>
        <h2>
          <strong>Confidențialitate</strong>
        </h2>
        <p>
          Colectăm și utilizăm datele dvs. personale în conformitate cu GDPR și
          cu Politica noastră de confidențialitate a datelor, care este
          disponibilă pe Site-ul web.
        </p>
        <p>
          Părțile declară că au luat cunoștință și respectă legislația
          românească și europeană privind protecția datelor cu caracter personal
          în desfășurarea activităților economice, incluzând, dar fără a se
          limita la, Directiva Europeană nr. 95/45/CE privind Regulamentul GDPR
          nr. 679/2016, cu modificările ulterioare.
        </p>
        <p>
          Aspectele referitoare la confidențialitatea și prelucrarea datelor cu
          caracter personal, în momentul plasării unei Comenzi și achiziționării
          Produsului furnizat, precum și în cazul încheierii altor acorduri sau
          contracte, sunt guvernate de Politica de confidențialitate, care
          reprezintă parte integrantă și care completează acești Termeni și
          Condiții. Vă rugăm să citiți cu atenție întregul conținut al Politicii
          de confidențialitate,{" "}
          <strong>
            <Link to="/privacy">
              <u>aici.</u>
            </Link>
          </strong>
        </p>
        <p>
          Parte din scopurile pentru care Market Structure FX colectează date
          sau solicită Utilizatorului furnizarea de date personale, pot fi: a)
          pentru a face posibilă livrarea Produselor comandate; b) pentru
          confirmarea Comenzii; c) pentru informarea Utilizatorilor privind
          stadiul Comenzii; d) pentru încheierea altor acorduri, contracte sau
          colaborări; e) pentru îndeplinirea obligațiilor legale; f) pentru
          oferirea de răspunsuri la notificările, sesizările sau reclamațiile
          formulate; g) scopuri de marketing și publicitate; h) scopuri de
          monitorizare a vânzărilor și comportament al Utilizatorilor și altele.
        </p>
        <p>
          Market Structure FX aduce la cunoștința Utilizatorilor faptul că
          anumite informații privind datele cu caracter personal care le
          aparțin, pot fi dezvăluite în temeiul solicitării și în limite
          prevăzute de lege, către diferite organe abilitate ale Statului,
          precum Parchete, Poliție, instanțe judecătorești și altele.
        </p>
        <p>
          Prin acceptarea prezentelor Termeni și Condiții, Utilizatorul declară
          că acceptă ca datele sale personale să poată fi incluse în baza de
          date a Market Structure FX și își dă în mod expres acordul ca datele
          sale personale furnizate să fie stocate, utilizate și prelucrate în
          scopul și limitele prevăzute în politica de confidențialitate, de
          către Market Structure FX și colaboratorii sau afiliații săi, dar și
          să fie cedate sau transferate afiliaților săi, precum și altor
          entități din țară sau străinătate, în baza unui angajament de
          confidențialitate din partea acestora și cu respectarea legislației în
          materie.
        </p>
        <p>
          Părțile se obligă și garantează păstrarea confidențialității
          Informațiilor confidențiale puse la dispoziție, inclusiv pentru
          angajații, agenții, colaboratorii ori afiliații acestora, prevenirea
          divulgării acestora către terțe părți neautorizate, precum și
          folosirea acestora numai în scopurile avute în vedere în prezentul
          document. Face excepție de la aceste prevederi utilizarea datelor în
          scopuri autorizate în prealabil și în scris de către Părți și sub
          rezerva termenilor stabiliți de acestea.
        </p>
        <p>
          Părțile sunt de acord că toate invențiile, know-how-ul, informațiile
          de afaceri, comerciale, de performanță cu privire la produse, tehnice
          și financiare sau orice informații desemnate în mod special ca fiind
          confidențiale ori care pot fi considerate în mod rezonabil ca fiind
          confidențiale, fără a fi marcate în mod expres astfel, dezvăluite de
          una dintre Părți, celeilalte Părți, constituie Informație
          confidențială și aparține Părții care face dezvăluirea.
        </p>
        <p>
          Nu fac obiectul prezentei secțiuni, nefiind considerate Informații
          confidențiale, cele care:
        </p>
        <p>
          – sunt sau au devenit publice, fără nicio intervenție din partea
          vreuneia dintre Părți;
        </p>
        <p>
          – sunt puse la dispoziția Părților pe baze neconfidențiale, din surse
          externe și care nu au fost supuse interdicției de dezvăluire;
        </p>
        <p>
          – sunt cunoscute de una dintre Părți anterior comunicării informației
          de către cealaltă Parte și fără nicio obligație de confidențialitate;
        </p>
        <p>
          – sunt solicitate în temeiul și cu permisiunea legii, în cadrul
          anumitor proceduri judiciare, de către diferite organe abilitate cu
          competență în materie.
        </p>
        <p>
          Utilizatorul confirmă faptul că nu va dezvălui Informațiile
          confidențiale și/sau Conținutul Produsului niciunei alte persoane,
          decât în cazul în care este necesar pentru ducerea la îndeplinire a
          anumitor obligații, cu excepția situației în care acesta este obligat
          conform legii în vigoare la divulgare. În caz contrar, Market
          Structure FX își rezervă dreptul de a se îndrepta împotriva
          Utilizatorului pentru recuperarea oricăror prejudicii cauzate ca
          urmare a dezvăluirii neautorizate.<br></br>
        </p>
        <br></br>
        <h2>
          <strong>Declinarea garanțiilor</strong>
        </h2>
        <p>
          SITE-UL ȘI PROGRAMELE SUNT OFERITE, FĂRĂ NICI O GARANȚIE DE NICI UN
          FEL, EXPRESĂ SAU IMPLICITĂ, INCLUZÂND, FĂRĂ LIMITĂRI, NICI O GARANȚIE
          PENTRU INFORMAȚII, SERVICII, ACCES NEÎNTRERUPT SAU PRODUSE FURNIZATE
          PRIN INTERMEDIUL SAU ÎN LEGĂTURĂ CU SITE-UL WEB, INCLUSIV, FĂRĂ A SE
          LIMITA LA SOFTWARE-UL LICENȚIAT ȘI REZULTATELE OBȚINUTE PRIN
          INTERMEDIUL SITE-ULUI WEB. ÎN MOD SPECIFIC, NE DECLINĂM ORICE
          GARANȚIE, INCLUSIV, DAR FĂRĂ A NE LIMITA LA: 1) ORICE GARANȚIE PRIVIND
          DISPONIBILITATEA, ACURATEȚEA, UTILITATEA SAU CONȚINUTUL INFORMAȚIILOR,
          PRODUSELOR SAU SERVICIILOR ȘI 2) ORICE GARANȚIE DE TITLU, GARANȚIE DE
          NERESPECTARE, GARANȚIE DE CALITATE COMERCIALĂ SAU DE ADECVARE LA UN
          ANUMIT SCOP.
        </p>
        <h2>
          <strong>Limitarea răspunderii</strong>{" "}
        </h2>
        <p>
          ÎN NICIUN CAZ NU vom fi răspunzători pentru orice daune directe,
          indirecte, accidentale, speciale sau subsecvente care rezultă din
          utilizarea sau incapacitatea de a utiliza site-ul web sau programele,
          inclusiv, fără a se limita la daunele rezultate din erori, omisiuni,
          întreruperi, ștergerea fișierelor, ERORI, DEFECTE, VIRUȘI, ÎNTÂRZIERI
          ÎN FUNCȚIONARE SAU TRANSMISIE SAU ORICE EȘEC DE PERFORMANȚĂ,
          INDIFERENT DACĂ ESTE SAU NU CAUZAT DE EVENIMENTE CARE NU SUNT SUB
          CONTROLUL NOSTRU REZONABIL, INCLUSIV, DAR FĂRĂ A SE LIMITA LA ACTE DE
          FORȚĂ MAJORĂ, DEFECȚIUNI ALE LINIILOR DE COMUNICAȚII, FURT, DISTRUGERE
          SAU ACCES NEAUTORIZAT LA ÎNREGISTRĂRILE, PROGRAMELE SAU SERVICIILE
          NOASTRE.
        </p>
        <h2>
          <strong>Despăgubire</strong>
        </h2>
        <p>
          Sunteți de acord să ne despăgubiți, să ne apărați și să ne exonerați
          pe noi și pe afiliații noștri, ofițerii, directorii, angajații,
          agenții și licențiatorii noștri de orice reclamație sau cerere,
          inclusiv onorariile rezonabile ale avocaților, făcute de orice terță
          parte din cauza sau ca urmare a utilizării de către dvs. a site-ului
          web sau a programelor, a încălcării de către dvs. a acestor Termeni
          sau a încălcării de către dvs. a oricăror drepturi ale altora.
        </p>
        <h2>
          <strong>Reziliere</strong>{" "}
        </h2>
        <p>
          Putem rezilia acești Termeni și accesul dvs. la Site-ul web și
          Programe în orice moment, cu sau fără motiv, fără a vă anunța. În
          momentul rezilierii, trebuie să încetați orice utilizare a Site-ului
          web și a Programelor și să distrugeți toate copiile de conținut de pe
          Site-ul web aflate în posesia dumneavoastră. În cazul în care
          suspendăm sau interzicem sau blocăm accesul dvs. la site-ul nostru web
          sau la o parte din site-ul nostru, nu trebuie să întreprindeți nici-o
          acțiune pentru a ocoli o astfel de suspendare sau interdicție sau
          blocare, inclusiv, fără limitare, crearea și / sau utilizarea unui
          cont diferit.
        </p>
        <h2>
          <strong>Politica de livrare a serviciilor</strong>
        </h2>
        <p>
          Livrarea serviciilor de îndrumare "ședințele 1:1" se va realiza
          exclusiv online prin accesarea și adaugarea unei rezervari in
          calendarul de sedinte ("Google Calendar"). Cursul online va fi
          disponibil imediat după achiziționarea pachetului (timp aproximativ de
          verificare a achiziției, o ora).
        </p>
        <h2>
          <strong>Excepția de garanție</strong>
        </h2>
        <p>
          Toate informațiile folosite pentru descrierea produselor (inclusiv,
          dar fără a se limita la, imagini statice ori dinamice, text
          descriptiv, prezentări grafice ori audio-video) nu impun nicio
          obligație din partea noastră, acestea având exclusiv rol de prezentare
          și informare.
        </p>
        <p>
          Utilizatorul înțelege, acceptă și este de acord cu faptul că în cazul
          produselor electronice sau digitale care presupun acces imediat,
          acesta nu va beneficia de garanție legală, în conformitate cu
          dispozițiile în vigoare.
        </p>
        <p>
          Cu toate acestea, întrucât Market Structure FX dorește să aibă în
          permanență utilizatori mulțumiți, acesta poate implementa o politică
          de garanție specială pentru anumite produse digitale, în sensul că
          poate returna sumele plătite de Utilizator, total sau parțial, într-un
          anumit termen și cu respectarea anumitor condiții. Condițiile exacte
          ale rambursării sumelor achitate sunt indicate în capitolul destinat
          acestei secțiuni din cadrul prezentelor Termeni și Condiții.
        </p>
        <h2>
          <strong>Dreptul de retragere / rambursare al Utilizatorului</strong>
        </h2>
        <p>
          <strong>Inexistența dreptului de retragere</strong>
        </p>
        <p>
          Utilizatorul înțelege și acceptă că nu beneficiază în mod automat de
          dreptul de retragere. Prin acceptarea acestor Termeni și Condiții,
          Utilizatorul își dă acordul prealabil și expres și confirmă că a luat
          cunoștință de faptul că își va pierde dreptul de retragere, în
          conformitate cu dispozițiile legale în vigoare aplicabile contractelor
          încheiate la distanță, precum și cu privire la drepturile
          consumatorilor în cadrul contractelor încheiate cu profesioniști,
          cuprinse și în cadrul OUG nr. 34/2014, care prevăd faptul că
          furnizarea de conținut digital, care nu este livrat pe un suport
          material, este exceptată de la dreptul de retragere.
        </p>
        <p>
          Cu toate acestea, Market Structure FX poate decide, la libera sa
          apreciere și discreție, că va rambursa anumite sume de bani, totale
          sau parțiale, în cazuri particulare și cu respectarea condițiilor
          menționate, însă acest fapt nu atrage sub nicio formă răspunderea sau
          obligația de a proceda într-o astfel de modalitate în mod constant.
        </p>
        <p>
          In cazul returnării Produsului digital, Utilizatorul va pierde de
          drept, în mod automat și accesul la orice cursuri, la platformă, la
          eventualele grupuri private, la webinarii, ședințe de coaching și la
          alte asemenea beneficii oferite de Market Structure FX, contul
          Utilizatorului urmând să fie șters.
        </p>
        {/* <p>
          Refund-ul se poate realiza doar dacă Uitilizatorul este eligibil
          pentru acesta. Pentru a fi eligibil trebuie să îndeplinești
          următoarele condiții: Mentoratul a fost achiziționat de maxim 14 zile
          și nu au fost accesate mai mult de 4 lectii din mentorat. În caz
          contrar, nu sunteți eligibili pentru un refund. Daca sunteți în afara
          acestor 2 cerințe, Market Structure FX rezervă dreptul de a refuza
          refund-ul.
        </p> */}
        <h2>
          <strong>Cesiune și transfer</strong>
        </h2>
        <p>
          Toate produsele furnizate de noi în conformitate cu acești Termeni și
          Condiții au valoare nominală, vă sunt personale și nu pot fi
          transferate sau atribuite niciunei alte persoane.
        </p>
        <p>
          Market Structure FX are dreptul de a atribui acești Termeni și
          Condiții oricărei alte companii, fără o notificare prealabilă.
        </p>
        <p>
          Market Structure FX își rezervă dreptul de a transfera, cesiona,
          greva, subcontracta sau înstrăina în orice alt mod Contractul sau
          oricare dintre drepturile și obligațiile acestuia care decurg din
          Contract, către orice terță parte, în mod discreționar.
        </p>
        <h2>
          <strong>Legea aplicabilă și jurisdicția competentă</strong>{" "}
        </h2>
        <p>
          Acești Termeni vor fi guvernați și interpretați în conformitate cu
          legile din România, fără a da efect oricăror principii de conflict de
          legi. Sunteți de acord să vă supuneți jurisdicției personale a
          instanțelor situate în România, România, pentru orice acțiuni pentru
          care ne păstrăm dreptul de a solicita măsuri asiguratorii sau alte
          măsuri echitabile la o instanță competentă pentru a preveni încălcarea
          reală sau amenințată, deturnarea sau încălcarea drepturilor noastre de
          autor, a mărcilor comerciale, a secretelor comerciale, a brevetelor
          sau a altor drepturi de proprietate intelectuală sau de proprietate.
        </p>
        <h2>
          <strong>Modificări ale Termenilor</strong>{" "}
        </h2>
        <p>
          Ne rezervăm dreptul de a modifica sau de a actualiza acești Termeni în
          orice moment și fără notificare prealabilă. Utilizarea în continuare a
          site-ului web și a programelor după orice astfel de modificări
          constituie acceptarea de către dvs. a noilor Termeni.
        </p>
        <h2>
          <strong>Riscurile tranzacționării</strong>{" "}
        </h2>
        <p>
          Tranzacționarea pe piețele financiare implica un grad ridicat de risc
          și este posibil să pierdeți întregul capital investit. Nu ne asumam
          responsabilitatea pentru pierderile suferite de către clienți.
        </p>
        <h2>
          <strong>Protecția consumatorului</strong>
        </h2>
        <p>
          Astfel cum a fost menționat anterior, Market Structure FX recunoaște
          și acceptă drepturile consumatorilor și declară că a ținut cont de
          prevederile în materie la momentul redactării prezentelor Termeni și
          Condiții și punerii la dispoziția Utilizatorului a Produselor
          furnizate.
        </p>
        <p>
          Pentru situații care pot fi în legătură cu protecția consumatorului,
          Utilizatorul poate accesa și verifica informațiile regăsite pe site-ul
          Autorității Naționale pentru Protecția Consumatorului (ANPC)
        </p>
        <h2>
          <strong>Întregul acord</strong>{" "}
        </h2>
        <p>
          Acești Termeni, împreună cu Politica noastră privind GDPR și Politica
          de confidențialitate a datelor, constituie întregul acord dintre
          dumneavoastră și Market Structure FX în ceea ce privește utilizarea
          Site-ului și a Programelor și înlocuiesc toate comunicările și
          propunerile anterioare sau contemporane, fie ele orale sau scrise,
          dintre dumneavoastră și Market Structure FX în ceea ce privește
          Site-ul și Programele.
        </p>
        <p>
          Dacă aveți întrebări sau nelămuriri cu privire la acești Termeni, vă
          rugăm să ne contactați la{" "}
          <a href="mailto:contact@marketstructurefx.ro">
            contact@marketstructurefx.ro
          </a>{" "}
        </p>
      </div>

      <Footer />
    </>
  );
}
