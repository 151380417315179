import { useContext, useState } from "react";
import useOutletTitle from "../../hooks/useOutletTitle";

import UserContainer from "../../components/UserContainer";
import { Link, useParams } from "react-router-dom";
import Collapsible from "../../components/Collapsible";
import { RiArrowLeftSLine } from "react-icons/ri";
import VideoPlayer from "../../components/VideoPlayer";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { getVideoURLs } from "../../api/courseApi";

export default function PlayCourse() {
  useOutletTitle("Cursuri");
  const { courseSlug } = useParams();
  console.log(courseSlug);
  const [videoMappings, setVideoMappings] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);

  useEffect(() => {
    // Fetch video URLs and mappings for the specific course
    getVideoURLs(courseSlug)
      .then((data) => {
        setVideoMappings(data.videoMappings);
        setVideoUrls(data.videoURLs);
      })
      .catch((error) => {
        toast.error("Error fetching video data");
        console.error("Error fetching video data:", error);
      });
  }, [courseSlug]);

  return (
    <>
      <UserContainer className="">
        <div className="w-full">
          <Link
            to="/user/courses"
            className="flex font-bold hover:text-darker-gray transition-all w-fit"
          >
            <RiArrowLeftSLine className="text-xl cursor-pointer pt-1" /> Inapoi
          </Link>
          {videoUrls?.map((videoUrl, index) => (
            <Collapsible
              key={index}
              titleNumber={videoMappings[index].videoNumber}
              title={videoMappings[index].videoTitle}
              containerClassName="fade-in w-full"
            >
              <div className="w-full h-auto">
                <VideoPlayer videoSource={videoUrl} />
              </div>
            </Collapsible>
          ))}
        </div>
      </UserContainer>
    </>
  );
}
