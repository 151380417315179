import { RiStarFill, RiVideoLine, RiHistoryFill } from "react-icons/ri";
import UserContext from "../context/UserContext";
import { useContext } from "react";
import { Link } from "react-router-dom";

export default function UserPanel({ active }) {
  const { user, logout } = useContext(UserContext);

  // const onUserLogout = () => {
  //   closePanels();
  //   logout();
  // };

  // Function to generate initials from first name and last name
  const generateInitials = (fullName) => {
    const names = fullName.split(" ");
    const firstName = names[0];
    const lastName = names[names.length - 1];
    return firstName.charAt(0) + lastName.charAt(0);
  };

  if (user)
    return (
      <>
        <div className="min-h-[10vh] bg-gray p-10 flex mt-6 rounded-xl w-full">
          <div className="flex flex-col xl:flex-row justify-between w-full">
            <div className="flex ">
              <div className="flex">
                <div className="w-12 h-12 bg-third rounded-full text-center flex justify-center items-center text-xl tracking-wide font-semibold">
                  {generateInitials(user.fullName)}
                </div>
                <div className="ml-4  flex flex-col justify-center">
                  Bun venit, <br></br>{" "}
                  <span className="text-xl font-bold">{user.fullName}</span>
                </div>
              </div>
            </div>
            <div className="flex ">
              <Link
                to="/user/courses"
                className={`flex cursor-pointer hover:scale-110 transition-all items-center mt-6 xl:mt-0 ${
                  active === "courses" ? "text-third" : ""
                }`}
              >
                <RiVideoLine className="text-2xl mr-2" />
                <div className={`mr-20 text-2xl `}>Cursuri</div>
              </Link>
              {/* <Link
                to="/user/history"
                className={`flex cursor-pointer hover:scale-110 transition-all ${
                  active === "history" ? "text-third" : ""
                }`}
              >
                <RiVideoLine className="text-2xl mr-2" />
                <div className="mr-20 text-2xl pt-1">Istoric de plata</div>
              </Link> */}
            </div>
          </div>
        </div>
      </>
    );
}
