import { RiStarFill } from "react-icons/ri";

export default function ReviewCard(props) {
  return (
    <>
      <div className="bg-primary max-w-sm p-8 rounded-xl mx-auto mb-4">
        <div className="flex items-center  mb-10">
          <div className="rounded-full bg-[#D9D9D9] w-14 h-14 mr-4 object-cover items-center">
            <img
              src={`${props.img}`}
              alt="review"
              className="rounded-full h-full object-cover items-center w-full scale-100"
            ></img>
          </div>
          <div className="">
            <div className="text-lg">{props.name}</div>
            <div className="flex">
              <RiStarFill className="text-yellow" />
              <RiStarFill className="text-yellow" />
              <RiStarFill className="text-yellow" />
              <RiStarFill className="text-yellow" />
              <RiStarFill className="text-yellow" />
            </div>
          </div>
        </div>
        <div className="text-md text-center">{props.text}</div>
      </div>
    </>
  );
}
