import { get, post, postJson, _deleteJson } from "./_methods";

export function createDiscountCode(formData) {
  return postJson("/admin/code", formData);
}

export function getExistingCodes(formData) {
  return get("/admin/existing-codes");
}

export function deleteCode(codeId) {
  return _deleteJson("/admin/delete-code", { codeId });
}
