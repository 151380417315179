import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Navbar from "../components/Navbar";
import Abstract from "../assets/images/abstract.png";
import Charts from "../assets/images/charts.png";
import GoldenCert from "../assets/images/cert_1.png";
import SilverCert from "../assets/images/cert_2.png";
import GreyChart from "../assets/images/grey_charts.png";
import BlackLabel from "../assets/images/blackfri2.png";
import * as Scroll from "react-scroll";
import CountUp from "react-countup";
import CountdownTimer from "../components/CountdownTimer";

import {
  RiCheckboxLine,
  RiArrowRightSLine,
  Ri24HoursFill,
  RiBookMarkFill,
  RiDraftFill,
  RiDiscordFill,
  RiVipFill,
  RiUserVoiceFill,
} from "react-icons/ri";
import BenefitCard from "../components/BenefitCard";
import Footer from "../components/Footer";
import Logo from "../assets/images/logoFX.png";
import LoginPanel from "../components/login/LoginPanel";
import Button from "../components/Button";
import ReviewCard from "../components/ReviewCard";
import UserContext from "../context/UserContext";
import firstReview from "../assets/images/review1.png";
import secondReview from "../assets/images/review2.png";
import thirdReview from "../assets/images/review3.png";

export default function Home() {
  const imageRef = useRef();
  const { user, isLoggedIn } = useContext(UserContext);
  const [showLoginPanel, setShowLoginPanel] = useState(false);
  const navigate = useNavigate();

  let ScrollLink = Scroll.Link;

  const onPlanCardClick = (courseSlug) => {
    if (!user) {
      setShowLoginPanel(true);
      localStorage.setItem("courseSlug", courseSlug);
    } else {
      if (user.plans) navigate(`/check-out/${courseSlug}`);
    }
  };

  return (
    <>
      <div id="view">
        <Navbar></Navbar>

        {showLoginPanel && (
          <LoginPanel onRequestClose={() => setShowLoginPanel(false)} />
        )}

        <main className="relative overflow-x-hidden">
          <section
            className="bg-bg-pattern bg-primary bg-cover min-h-[100vh]"
            name="home"
          >
            <div className="container pt-40 min-h-[80vh] ">
              <div className="">
                <div className="text-content">
                  <div className="text-[40px] xl:text-[60px] font-semibold">
                    Lipsa de profit pe piața{" "}
                    <span className="text-third">Forex</span>
                    <br></br>
                    te <span className="text-third">descurajează?</span>{" "}
                  </div>
                  <div className="max-w-4xl mt-20 text-darker-gray">
                    Hai să schimbăm asta împreună! Vino să înveți cum să
                    înțelegi și să tranzacționezi cu încredere piețele
                    financiare. Înțelege analizele, strategiile și tendințele
                    pieței de la 0 la avansat cu ajutorul nostru. Suntem aici
                    pentru a-ți oferi îndrumarea și suportul necesar.
                  </div>
                </div>

                <img
                  src={Abstract}
                  className="abstract-img rotate-initially"
                  alt="abstract"
                  ref={imageRef}
                />

                <div className="flex mt-20">
                  <Link
                    to="https://discord.gg/ab9TQzXgAN"
                    target="_blank"
                    className="grup"
                  >
                    <Button
                      text="Grup Discord"
                      icon={RiArrowRightSLine}
                      iconRight={RiArrowRightSLine}
                      containerClassName="text-content mr-4"
                      caret
                    />
                  </Link>
                  <Link to="/hftpassing">
                    <div className="rounded-full ring-2 ring-third px-6 py-2 hover:bg-third transition-all cursor-pointer w-fit relative">
                      Finanțare conturi
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="fade-up-bg w-full h-20"></div>

            <div className="fade-down w-full h-20"></div>
            <div className="bg-secondary">
              <div className="container flex flex-col xl:flex-row py-20">
                <div className="w-full mr-8 mb-10 xl:mb-0">
                  <div className="relative">
                    <div className="w-fit bg-light-gray p-6 px-8 rounded-xl shadow-lg absolute xl:left-[3%] top-[-20%] xl:top-0">
                      <div className="text-[#55fdfd] text-5xl font-bold flex">
                        <CountUp
                          start={0}
                          end={4}
                          enableScrollSpy
                          scrollSpyDelay={500}
                          duration={4}
                        >
                          {({ countUpRef }) => (
                            <div className="mr-2">
                              <span ref={countUpRef} />
                            </div>
                          )}
                        </CountUp>
                        ani
                      </div>
                      <div className="text-xl font-semibold">
                        experiență <br></br>în trading
                      </div>
                    </div>
                    <div className="w-full ">
                      <img
                        src={Charts}
                        className="mb-8 xl:mb-0 z-50 relative mx-auto object-cover w-full xl:w-auto max-w-md"
                        alt="charts"
                      />
                    </div>
                    <div className="w-[173px] bg-light-gray p-6 px-8 rounded-xl shadow-lg absolute bottom-[-10%] xl:bottom-[0%] right-0 xl:right-[5%]">
                      <div className="text-[#55fdfd] text-5xl font-bold flex w-max">
                        <CountUp
                          start={0}
                          end={2000}
                          enableScrollSpy
                          scrollSpyDelay={500}
                        >
                          {({ countUpRef }) => (
                            <div className="mr-1">
                              <span ref={countUpRef} />
                            </div>
                          )}
                        </CountUp>
                        +
                      </div>
                      <div className="text-xl font-semibold">
                        tranzacții <br></br>live
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:ml-20">
                  <div className="text-[40px] xl:text-[50px] font-semibold">
                    Descoperă Lumea{` `}
                    <span className="text-third">Forex</span> <br></br>
                    Fiecare <span className="text-third">decizie</span> contează
                  </div>
                  <p className="text-darker-gray  my-10 ">
                    În lumea Forex, fiecare pas este important. Descoperă cum
                    fiecare decizie poate să te aducă mai aproape de obiectivele
                    tale. Învață cum să utilizezi informațiile și analizele
                    pentru a lua decizii informate, doar așa poți să anticipezi
                    direcția trendului. Fiecare astfel de abilitate dezvoltată
                    și fiecare decizie corectă te poate aduce mai aproape de
                    obiectivele financiare pe care ți le-ai propus.
                  </p>
                  <ScrollLink to="beneficii" smooth={true} offset={0}>
                    <Button text="Află mai multe" containerClassName="" caret />{" "}
                  </ScrollLink>
                </div>
              </div>
              <div className="fade-up w-full h-20"></div>
            </div>
          </section>

          <section className="bg-primary " name="mentor">
            <div className="container  b-20 pt-20 xl:pt-40">
              <div className="flex flex-col xl:flex-row xl:mb-16">
                <div className="xl:max-w-1/2">
                  <div className="text-[40px] xl:text-[50px] font-semibold">
                    Despre{` `}
                    <span className="text-third">Mentor</span> {` `}
                    <br></br>
                    Expertiză și {` `}
                    <span className="text-third">Îndrumare</span> {` `}
                  </div>
                  <p className="mt-8 text-darker-gray">
                    Întâlnește mentorul nostru cu o experiență solidă de peste 4
                    ani în tranzacționare! Cu o carieră impresionantă pe piețele
                    financiare, el a acumulat cunoștințe profunde în analiza
                    pieței, gestionarea riscurilor și dezvoltarea strategiilor
                    de succes. Mai mult de atât, mentorul nostru are nu doar
                    expertiză, ci și realizări concrete, obținând rezultate
                    notabile prin gestionarea atentă a conturilor de finanțare.
                  </p>
                </div>
                <div className="w-full flex justify-center xl:mt-8">
                  <img
                    src={GoldenCert}
                    className="my-8 xl:my-0 max-w-[420px] h-fit"
                    alt="Golden Certification"
                  />
                </div>
              </div>
              <div className="flex flex-col xl:flex-row pb-10 max-w-full">
                <div className="w-full flex justify-center">
                  <img
                    src={SilverCert}
                    className="max-w-[420px] h-fit mb-8"
                    alt="Golden Certification"
                  />
                </div>
                <div className="items-center flex flex-col justify-center">
                  <p className="mt-2 text-darker-gray xl:ml-20">
                    Fie că te afli în primele etape ale călătoriei tale în lumea
                    tranzacționării sau ești deja familiarizat cu aspectele
                    sale, eu sunt aici pentru a fi ghidul tău de încredere. Vom
                    analiza împreună strategiile tale actuale și vom explora
                    moduri de a le optimiza și perfecționa.
                  </p>
                  <p className="mt-8 text-darker-gray xl:ml-20">
                    În plus, voi împărtăși cu tine perspective noi, tehnici
                    avansate și tendințe recente din industrie, pentru a-ți
                    susține creșterea continuă și a-ți îmbunătăți performanța.
                    Vreau să te ajut să dezvolți o strategie care să se
                    potrivească perfect cu stilul tău de viață.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <div className="block"></div>

          <section className="bg-grey-charts bg-primary" name="cursuri">
            <div className="container py-20">
              <div className="text-6xl font-bold mb-10 justify-center text-center items-center">
                <div>Cursurile</div>
                <img
                  src={Logo}
                  className="md:max-w-sm mx-auto max-w-xs"
                  alt="logo"
                />
                {/* <div className="xl:flex justify-center items-center leading-tight my-8">
                  <div className="text-stroke text-[70px] xl:text-[100px] xl:mr-8 ">
                    BLACK
                  </div>
                  <div className="text-[70px] xl:text-[100px]">FRIDAY</div>
                </div>
                <CountdownTimer /> */}
              </div>
              <div className="flex flex-col xl:flex-row justify-center items-center w-full">
                {/* CARD */}
                <div className="relative p-10  bg-secondary rounded-md hover:ring-4 ring-third xl:hover:scale-105 transition-all cursor-pointer w-full xl:w-1/3 xl:h-1/2 flex justify-between flex-col ">
                  {/* <img
                    src={BlackLabel}
                    alt="black-friday"
                    className="absolute top-0 right-8 w-[65px]"
                  /> */}
                  <div>
                    <div className="font-bold mb-8 text-xl">
                      MENTORAT PRIVAT
                    </div>
                    {/* <span className="text-red text-xl font-bold line-through mr-4">
                      300 EUR
                    </span> */}
                    <div className="mb-6">
                      <span className="text-third text-4xl font-bold">
                        200 EUR
                      </span>
                    </div>
                    <hr className="mb-10 opacity-20"></hr>
                    <div className="text-sm mb-8 xl:mb-0">
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mr-2 mt-1 text-green" />
                        <div className="">Curs complet de Tranzacționare</div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mr-2 mt-1 text-green" />
                        <div className="w-fit">
                          Feedback direct din partea Mentorului
                        </div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mr-2 mt-1 text-green" />
                        <div className="w-fit">
                          Acces la secțiunea Privată pe Discord
                        </div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mr-2 mt-1 text-green" />
                        <div className=" w-fit">
                          Structura Pieței determinată de Inducement
                        </div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mr-2 mt-1 text-green" />
                        <div className="w-fit">
                          Mișcări de Impulsivitate & Corecție
                        </div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mr-2 mt-1 text-green" />
                        <div className="w-fit">
                          Analiză Tehnică & Fundamentală
                        </div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mr-2 mt-1 text-green" />
                        <div className="w-fit">
                          Determinarea Order Flow-ului
                        </div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mr-2 mt-1 text-green" />
                        <div className="w-fit">Formațiuni de Candele</div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mr-2 mt-1 text-green" />
                        <div className="w-fit">Managementul Riscului</div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mr-2 mt-1 text-green" />
                        <div className="w-fit">Psihologie</div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mr-2 mt-1 text-green" />
                        <div className="w-fit">
                          6 Tipuri de Execuție în Piață
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="text-center bg-third text-black py-3 rounded-lg cursor-pointer hover:scale-110 hover:text-white transition-all mt-20 text-md"
                    onClick={() => onPlanCardClick("standard")}
                  >
                    Alege plan
                  </div>
                </div>

                {/* CARD */}

                <div className="card w-full xl:w-[40%] p-10  bg-secondary xl:mx-10  cursor-pointer my-8 xl:my-0">
                  <div className="flex items-center mb-8">
                    <div className="font-bold text-3xl mr-2">PREMIUM</div>
                    <span className="text-red text-xl font-bold line-through mr-4">
                      1100 EUR
                    </span>
                  </div>
                  <div className="mb-6">
                    <span className="text-third text-6xl font-bold">
                      500 EUR
                    </span>
                  </div>
                  <hr className="mb-10 opacity-20"></hr>

                  <div className="text-[16px] h-full">
                    <div className="flex mb-1.5">
                      <RiCheckboxLine className="mt-1 mr-2 text-green" />
                      <div className=" text-green w-fit">
                        Acces mentorat privat + 4 sedințe 1-1
                      </div>
                    </div>

                    <div className=" mb-1.5">
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mt-1 mr-2 text-green" />
                        <div className="w-fit">
                          Acces permanent la comunitatea privată
                        </div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mt-1 mr-2 text-green" />
                        <div className="w-fit">
                          Determinarea unui stil de tranzacționare personalizat
                        </div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mt-1 mr-2 text-green" />
                        <div className="w-fit">Perfecționarea strategiei</div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mt-1 mr-2 text-green" />
                        <div className="w-fit">Analiză și practică live</div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mt-1 mr-2 text-green" />
                        <div className="w-fit">
                          Plan de scalare personalizat
                        </div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mt-1 mr-2 text-green" />
                        <div className="w-fit">Psihologie</div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mt-1 mr-2 text-green" />
                        <div className="w-fit">
                          Tips & Tricks în vederea obținerii unei finanțări
                        </div>
                      </div>
                    </div>

                    <div
                      className="text-center bg-third text-black py-3 rounded-lg mt-14 xl:mt-20 cursor-pointer hover:scale-110 hover:text-white transition-all text-lg"
                      onClick={() => onPlanCardClick("premium")}
                    >
                      Alege plan
                    </div>
                  </div>
                </div>
                {/* CARD */}
                <div className="relative p-10 bg-secondary rounded-md hover:ring-4 ring-third xl:hover:scale-105 transition-all cursor-pointer w-full xl:w-1/3 xl:h-1/2 flex justify-between flex-col">
                  {/* <img
                    src={BlackLabel}
                    alt="black-friday"
                    className="absolute top-0 right-8 w-[65px]"
                  /> */}
                  <div>
                    <div className="font-bold mb-8 text-xl">
                      SCALPING <span className="text-[#ffd700]">XAUUSD</span>
                    </div>
                    {/* <span className="text-red text-xl font-bold line-through mr-4">
                      1000 EUR
                    </span> */}
                    <div className="mb-6">
                      <span className="text-third text-4xl font-bold">
                        250 EUR
                      </span>
                    </div>
                    <hr className="mb-6 opacity-20"></hr>
                    <div className="text-sm w-full text-start">
                      <div className="flex mb-4">
                        <div className="w-fit text-darker-gray">
                          Cursul nostru complet de tranzacționare vă oferă șansa
                          unică de a învăța și de a profita de cea mai volatilă
                          și interesantă piață financiară din lume - piața
                          aurului.
                        </div>
                      </div>

                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mt-1 mr-2 text-green w-4" />
                        <div className="w-fit">
                          Tranzacționarea Aurului Unilateral (Într-o Singură
                          Direcție)
                        </div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mt-1 mr-2 text-green w-4" />
                        <div className="w-fit">
                          Descoperirea Zonelor Cruciale pentru Execuția
                          Tranzacțiilor
                        </div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mt-1 mr-2 text-green w-4" />
                        <div className=" w-fit">
                          Cunoștințe Avansate Despre Determinarea Bias-ului
                        </div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mt-1 mr-2 text-green w-4" />
                        <div className="w-fit">
                          Analiză Economico-Financiară
                        </div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mt-1 mr-2 text-green w-4" />
                        <div className="w-fit">Analiză Tehnică</div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mt-1 mr-2 text-green w-4" />
                        <div className="w-fit">Fragmentarea Prețului</div>
                      </div>
                      <div className="flex mb-1.5">
                        <RiCheckboxLine className="mt-1 mr-2 text-green w-4" />
                        <div className="w-fit">
                          3 Tipuri de Execuție în Piață
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="text-center bg-third text-black py-3 rounded-lg mt-14 cursor-pointer hover:scale-110 hover:text-white transition-all text-md"
                    onClick={() => onPlanCardClick("scalping")}
                  >
                    Alege plan
                  </div>
                </div>
              </div>
            </div>
            <div className="fade-up-bg w-full h-20"></div>
          </section>

          <section
            className="bg-bg-pattern bg-primary bg-no-repeat bg-cover"
            name="beneficii"
          >
            <div className="fade-down-bg w-full h-20"></div>
            <div className="container">
              <div className="text-6xl font-bold text-center mb-10">
                <div>Beneficii</div>
              </div>
              <div className="grid md:grid-cols-2 xl:grid-cols-3 grid-row-2">
                {/* card */}
                <BenefitCard
                  icon={<RiBookMarkFill />}
                  title="Resurse 
                Avansate"
                  text="Oferim resurse educative de înaltă calitate, adaptate atât pentru începători, cât și pentru traderii cu experiență. Cu ajutorul acestora, poți să-ți dezvolți strategii personalizate, să-ți îmbunătățești performanță și să iei decizii mai bune în tranzacționarea ta.                  "
                  className=""
                ></BenefitCard>
                <BenefitCard
                  icon={<RiDraftFill />}
                  title="Evaluări 
                Personalizate"
                  text="Beneficiezi de evaluări individuale din partea mentorului nostru pentru a-ți urmări progresul și a primi feedback direct care să te ajute să evoluezi constant și să dezvolți o strategie care să se potrivească perfect cu stilul tău de viață. "
                ></BenefitCard>
                <BenefitCard
                  icon={<RiUserVoiceFill />}
                  title="Sesiuni 
                private 1:1"
                  text="Mentorul va efectua o analiză amănunțită a stilului tău de tranzacționare, a obiectivelor pe care le-ai definit și a abilităților pe care le-ai dobândit până acum. Pe baza acestor informații, vom crea un plan de dezvoltare personalizat, adaptat în mod specific nevoilor și aspirațiilor tale."
                ></BenefitCard>
                <BenefitCard
                  icon={<RiVipFill />}
                  title="Evenimente 
                VIP"
                  text="Fii parte din evenimente live, webinarii exclusive și seminarii interactive, conduse de mentorul nostru."
                ></BenefitCard>
                <BenefitCard
                  icon={<RiDiscordFill />}
                  title="Grup privat"
                  text="În cadrul cursului nostru, vei avea acces la un grup privat pe Discord, exclusiv, unde te vei conecta cu alți traderi dornici să învețe și să împărtășească idei.                  "
                ></BenefitCard>
                <BenefitCard
                  icon={<Ri24HoursFill />}
                  title="Suport 24/7"
                  text="Beneficiezi de asistență tehnică pentru orice întrebare direct
                  de la mentor."
                ></BenefitCard>{" "}
              </div>
              <div className="flex justify-center">
                <ScrollLink to="cursuri" smooth={true} offset={0}>
                  <Button text="Cumpără acum" containerClassName="" caret />
                </ScrollLink>
              </div>
            </div>
            <div className="fade-up-bg w-full h-20"></div>
          </section>

          <section className="bg-secondary">
            <div className="fade-down w-full h-20"></div>
            <div className="text-6xl font-bold text-center mb-10">
              <div>Recenzii</div>
            </div>
            <div className="container flex flex-col xl:flex-row">
              <ReviewCard
                img={firstReview}
                name="Bombea George"
                text="Robert m-a ajutat în mod semnificativ cu structurarea și analiza pieței în sesiunile de 1-1, și pot spune că acest lucru a făcut o diferență semnificativă în abordarea mea a tranzacționării. Cu ajutorul său, am reușit să dezvolt o perspectivă mai clară asupra modului în care funcționează piața și să-mi îmbunătățesc abilitățile de tranzacționare."
              />
              <ReviewCard
                img={secondReview}
                name="Benu Marinescu"
                text="Robert este cu adevărat un expert în domeniul tranzacțiilor pe piața Forex, și nu numai că are o înțelegere profundă a pieței, dar și abilitatea de a o explica în mod clar și accesibil. Sesiunile de consultanță personalizate au facut diferența în dezvoltarea mea ca trader."
              />
              <ReviewCard
                img={thirdReview}
                name="Tudor Valentin"
                text="Seriozitatea în Forex este cheia succesului. Este esențial să se respecte regulile și să se aibă încredere în procesul de învățare și dezvoltare. Recomand cu căldură celor care doresc să se implice în tranzacționarea pe piața Forex să caute servicii de mentorat privat, cu un mentor serios și experimentat ca Robert. Acest lucru poate face o diferență semnificativă în construirea unei cariere de succes."
              />
            </div>
            <div className="fade-up w-full h-20"></div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
}
