import { useEffect } from "react";

export const defaultTitle = "Market Structure FX";

export default function usePageTitle(title) {
  useEffect(() => {
    window.document.title = title || defaultTitle;

    return () => {
      window.document.title = defaultTitle;
    };
  }, [title]);
}
