import { get, post, postJson } from "./_methods";

export function getOrder(number) {
  return get(`/orders/${number}`);
}

export function getPlan(slug) {
  return get(`/plans/${slug}`);
}

export function checkCode(codeName) {
  return get(`/codes/${codeName}`);
}

// Creates an order from the user's cart
export function order({ formData, planName }) {
  formData.append("planName", planName);
  return post("/orders", formData);
}
