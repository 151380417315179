import { useEffect, useState, useContext } from "react";
import Logo from "../assets/images/logo_alb.png";
import { Link } from "react-router-dom";
import Button from "./Button";

import UserContext from "../context/UserContext";

export default function CourseCard({ title, goTo, secondaryText }) {
  return (
    <>
      <div className="bg-light-gray p-10 flex flex-col justify-between text-center rounded-xl hover:ring-2 transition-all mb-4 lg:mr-8 max-w-full md:max-w-xs">
        <img src={Logo} className="w-60 mx-auto" alt="logo" />
        <div className="my-10 text-lg font-semibold">{title}</div>
        {secondaryText && (
          <span className="text-md text-darker-gray">
            Pentru efectuarea ședințelor 1-1 ne puteți scrie pe
            contact@marketstructurefx.ro sau în privat pe Discord. (k1m)
          </span>
        )}
        {/* <div className="mb-10">16% Completat</div> */}
        {goTo && (
          <Link to={`./${goTo}`} text="" className="text-center btn">
            Redă curs
          </Link>
        )}
      </div>
    </>
  );
}
