import { useState, useEffect } from 'react';
import { get } from '../../api/_methods';
import useOutletTitle from '../../hooks/useOutletTitle';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import usePrimaryBg from '../../hooks/usePrimaryBg';
import { InputText } from '../../components/Input';
import Button from '../../components/Button';
import Form from '../../components/Form';
import { createDiscountCode } from '../../api/adminApi';
import { toast } from 'react-toastify';
import { getExistingCodes, deleteCode } from '../../api/adminApi';
import { RiDeleteBin2Line } from 'react-icons/ri';

export default function AdminCodes({ className }) {
  useOutletTitle('Coduri de reducere');
  usePrimaryBg();

  const [filter, setFilter] = useState(null);
  const [value, onChange] = useState();
  const [existingCodes, setExistingCodes] = useState([]);
  const [fetchCodesTrigger, setFetchCodesTrigger] = useState(false);

  const onAddDiscountCode = async (formData, e) => {
    const discountCode = Object.fromEntries(formData);

    const percentage = parseInt(discountCode.percentage);
    if (isNaN(percentage) || percentage < 1 || percentage > 99) {
      return toast.error(
        'Procentajul de reducere trebuie să fie între 1 și 99.'
      );
    }

    if (
      !discountCode.name ||
      !discountCode.percentage ||
      !discountCode.endDate
    ) {
      return toast.error('Te rog să introduci toate datele.');
    }

    const res = await createDiscountCode(discountCode);

    if (res.error) {
      toast.error(res.error);
    } else {
      toast.success(res.message);
      setFetchCodesTrigger(!fetchCodesTrigger); // Trigger fetching codes again
      e.target.reset();
    }
  };

  useEffect(() => {
    async function fetchCodes() {
      const res = await getExistingCodes();
      if (res.error) {
        toast.error(res.error);
      } else {
        setExistingCodes(
          res.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
      }
    }
    fetchCodes();
  }, [fetchCodesTrigger]);

  const onDeleteCode = async (codeId) => {
    const res = await deleteCode(codeId);

    if (res.error) {
      toast.error(res.error);
    } else {
      toast.success(res.message);
      setFetchCodesTrigger(!fetchCodesTrigger); // Trigger fetching codes again
    }
  };

  return (
    <>
      <Form className="flex" onSubmit={onAddDiscountCode}>
        <InputText
          type="text"
          name="name"
          placeHolder="Scrie cod.."
          className="p-3 rounded-lg hover:ring-2 ring-third bg-primary w-[200px] focus:ring-2 focus:outline-none rounded-r-none !ring-0"
          containerClassName="!w-[200px] !mb-0"
        />
        <InputText
          type="text"
          name="percentage"
          placeHolder="%"
          className="p-3 rounded-lg hover:ring-2 ring-third bg-primary w-[60px] focus:ring-2 focus:outline-none rounded-l-none rounded-r-none !ring-0"
          containerClassName="!w-[60px] !mb-0"
        />
        <DatePicker
          onChange={onChange}
          value={value}
          name="endDate"
          className="text-white bg-primary"
          calendarClassName="!bg-primary !border-0 rounded-lg"
        />
        <button type="submit" className="bg-third px-4 rounded-r-md">
          Adaugă
        </button>
      </Form>

      <div className="my-4 text-xl">Coduri</div>
      {existingCodes.map((code) => {
        const today = new Date();
        const endDate = new Date(code.endDate);

        const status = endDate >= today ? 'Activ' : 'Inactiv';
        const statusClassName = status === 'Activ' ? 'bg-green' : 'bg-red';

        return (
          <div
            key={code._id}
            className="bg-primary w-fit p-4 min-w-[300px] rounded-md mb-2"
          >
            <div className="flex items-center mb-4 justify-between">
              <div className="text-xl">{code.name}</div>
              <RiDeleteBin2Line
                className="text-xl cursor-pointer"
                onClick={() => onDeleteCode(code._id)}
              />
            </div>
            <div>
              <span className="text-lighter-gray mr-2">Procent:</span>
              {code.percentage}%
            </div>
            <div className="flex">
              <span className="text-lighter-gray mr-2">Valabil până la:</span>
              <div>{code.endDate.split('T')[0]}</div>
            </div>
            <div className="flex">
              <span className="text-lighter-gray mr-2">Status:</span>
              <div className={`${statusClassName} px-2 rounded-md`}>
                {status}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
