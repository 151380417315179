import useScrollToTop from "../../hooks/useScrollToTop";
import { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import AdminMenu, { ADMIN_PAGE_LINKS } from "./AdminMenu";
import usePrimaryBg from "../../hooks/usePrimaryBg";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

export default function AdminDashboard() {
  usePrimaryBg();
  const [title, setTitle] = useState("");
  const location = useLocation();

  useScrollToTop([location]);

  return (
    <>
      <Navbar></Navbar>
      <div className="container !px-2.5 my-5 md:my-9">
        <div className="flex -mt-3 mb-3 md:hidden flex-wrap">
          {/* {ADMIN_PAGE_LINKS.map((link) => (
          <Link key={link.path} to={link.path} className="link mr-4">
            {link.text}
          </Link>
        ))} */}
        </div>
        <p className="lg:ml-56 text-2xl text-white tracking-wide font-semibold">
          {title || "Coduri de reducere"}
        </p>

        <div className="flex items-start mt-3">
          {/* User menu */}
          <div className="hidden lg:block lg:mr-4 shrink-0 sticky top-20 bg-secondary rounded-xl">
            <AdminMenu />
          </div>

          {/* Page content */}
          <div className="w-full bg-secondary rounded-xl min-h-[40vh] p-10">
            <Outlet context={[setTitle]} />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
