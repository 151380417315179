import Button from "./Button";
import Logo from "../assets/images/logoFX.png";
import { Link } from "react-router-dom";
import {
  RiInstagramFill,
  RiFacebookCircleFill,
  RiTwitterFill,
} from "react-icons/ri";
import Sal from "../assets/images/SAL.png";
import Sol from "../assets/images/SOL.png";

export default function Footer(props) {
  return (
    <footer className="bg-primary py-10 xl:py-20">
      <div className="container min-h">
        <img src={Logo} className="max-w-xs" alt="Logo" />
        <div className="flex">
          <div className="text-darker-gray py-10 xl:mr-40 flex flex-col w-full">
            <Link className="mb-4" to="/terms">
              Termeni și condiții
            </Link>
            <Link className="mb-4" to="/privacy">
              Politică de confidențialitate
            </Link>
            <div className="flex items-center">
              <a href="mailto:contact@marketstructurefx.ro">
                <span className="text-md">contact@marketstructurefx.ro</span>
              </a>
            </div>
            <Link
              to="https://www.instagram.com/marketstructurefx.ro/"
              target="_blank"
              className=""
            >
              <RiInstagramFill className="my-4 mr-10 text-2xl text-darker-gray cursor-pointer hover:scale-125 transition-all" />
            </Link>
            <div className="flex flex-col lg:flex-row">
              <Link
                className="my-2 mr-4"
                to="https://anpc.ro/ce-este-sal/"
                target="_blank"
              >
                <img src={Sal} alt="SAL ANPC" className="w-36" />
              </Link>
              <Link
                className="my-2"
                to="https://ec.europa.eu/consumers/odr"
                target="_blank"
              >
                <img src={Sol} alt="SOL ANPC" className="w-36" />
              </Link>
            </div>
          </div>
          {/* <div className="text-darker-gray py-10">
            <div className="mb-4">Termeni și condiții</div>
            <div className="mb-4">Politică de confidențialitate</div>
            <div className="mb-4">Politică cookies</div>
            <div className="mb-4">Contact</div>
          </div> */}
        </div>
        <div className="flex">
          {/* <RiFacebookCircleFill className="mr-10 text-2xl text-darker-gray cursor-pointer hover:scale-125 transition-all" />
          <RiTwitterFill className="text-2xl text-darker-gray cursor-pointer hover:scale-125 transition-all" /> */}
        </div>
      </div>
    </footer>
  );
}
