import LoadingSpinner from "./LoadingSpinner";
import { RiArrowRightSLine } from "react-icons/ri";

export default function Button({
  type = "button",
  containerClassName = "",
  className = "",
  size = "default",
  text,
  onClick,
  icon,
  iconRight,
  loading = false,
  caret,
  ...restProps
}) {
  if (size === "small") {
    containerClassName += " !py-1 !px-3 !text-md !rounded-sm";
  }

  const onClickProxy = (e) => {
    if (loading) {
      e.preventDefault();
      return;
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      type={type}
      className={`btn ${containerClassName}`}
      onClick={onClickProxy}
      {...restProps}
    >
      {loading && (
        <div className="loading-spinner-container">
          <LoadingSpinner size={24} />
        </div>
      )}
      <div className="flex justify-center">
        <div
          className={`flex items-center justify-center ${
            loading ? "opacity-0" : ""
          } ${className}`}
        >
          {!iconRight && icon}
          {text}
          {iconRight && icon}
          {caret && (
            <>
              <RiArrowRightSLine className=" ml-1.5 text-xl" />
            </>
          )}
        </div>
      </div>
    </button>
  );
}
