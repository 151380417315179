import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-center pt-[5%] px-3 text-white bg-primary h-full">
          <div>
            <h1 className="text-[150px]">🔥</h1>
          </div>
          <p>Oops! Am dat de o eroare...</p>
          <br />
          <small className="text-red">Error: {this.state.error.message}</small>
        </div>
      );
    }

    return this.props.children;
  }
}
