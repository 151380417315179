export default function UserContainer({
  id = "",
  className = "",
  children,
  forwardRef,
  onClick,
}) {
  return (
    <div
      id={id}
      ref={forwardRef}
      className={`flex flex-col lg:flex-row bg-gray rounded-xl mt-6 p-6 ${className} flex`}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
