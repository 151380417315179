import UserContext from "../../context/UserContext";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postJson } from "../../api/_methods";
import Form from "../Form";
import { InputText } from "../Input";

import { UIModalCloseButton } from "../../components/modals/UIModal";

export default function Login({ goToRegister, onClose }) {
  const { login } = useContext(UserContext);
  const navigate = useNavigate();

  const onLogin = async (formData, e) => {
    const { email, password } = Object.fromEntries(formData);

    if (!email || !password) {
      return toast.error("Lipsă credențiale!");
    }

    const res = await postJson("/login", { email, password });

    if (res.error) {
      toast.error(res.error);
    } else {
      login(res);
      onClose(res);
      const courseSlug = localStorage.getItem("courseSlug");
      if (courseSlug) {
        // Clear the stored courseSlug from local storage
        localStorage.removeItem("courseSlug");
        // Redirect to the courseSlug URL
        navigate(`/check-out/${courseSlug}`);
      } else {
        // Redirect to a default page after successful login
        navigate("/user/courses"); // Change this to the desired default URL
      }
    }
  };

  return (
    <>
      <UIModalCloseButton onClick={onClose} />

      <div className="mt-3 px-5 pb-7 md:px-8 md:pb-12 text-white">
        <h2 className="text-center font-semibold text-[26px] mt-12">
          Intră în cont
        </h2>
        <div className="text-center mb-8 mt-4">
          <span>Nu ai cont?</span>
          <span
            className="ml-1 link select-none text-blue cursor-pointer hover:underline"
            onClick={goToRegister}
          >
            Înregistrează-te!
          </span>
        </div>
        <Form className="" onSubmit={onLogin}>
          <InputText
            type="email"
            name="email"
            placeHolder="Email"
            className="p-3 rounded-lg hover:ring-2 ring-third bg-primary  w-full focus:ring-2 focus:outline-none"
          ></InputText>
          <InputText
            type="password"
            name="password"
            placeHolder="Parolă"
            className="p-3 rounded-lg hover:ring-2 ring-third bg-primary w-full focus:ring-2 focus:outline-none"
          ></InputText>
          <button
            type="submit"
            value="Submit"
            className="w-full rounded-lg bg-third hover:bg-darker-third transition-all text-white py-2 mt-2"
          >
            Intră în cont
          </button>
          {/* <Link
            to="/forgot-password"
            className="text-sm mt-12 block text-center"
          >
            Ai uitat parola?
          </Link> */}
        </Form>
      </div>
    </>
  );
}
