import { useEffect } from "react";
import { RiLoader2Fill } from "react-icons/ri";

export default function LoadingSpinner({
  className = "",
  size = 20,
  scrollToTop = false,
  late = false,
}) {
  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [scrollToTop]);

  const render = () => (
    <RiLoader2Fill size={size} className={`loading-spinner ${className}`} />
  );

  if (late) {
    return <div className="late-fade-in">{render()}</div>;
  }

  return render();
}
