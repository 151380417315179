import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postJson } from "../../api/_methods";
import Form from "../Form";
import { InputText } from "../Input";

import { Link } from "react-router-dom";
import UserContext from "../../context/UserContext";

import { UIModalCloseButton } from "../../components/modals/UIModal";

export default function Register({ goToLogin, onClose }) {
  const { login } = useContext(UserContext);
  const navigate = useNavigate();

  const onRegister = async (formData, e) => {
    const { email, password, fullName, confirmPass, termsAccepted } =
      Object.fromEntries(formData);

    if (!email || !password || !fullName) {
      return toast.error("Te rog să introduci toate datele.");
    }

    if (fullName.split(" ").length < 2) {
      return toast.error("Te rog să introduci numele complet.");
    }

    if (confirmPass !== password) {
      return toast.error("Nu ați introdus aceeași parolă.");
    }

    if (!termsAccepted) {
      return toast.error("Trebuie să fii de acord cu Termenii și Condițiile.");
    }

    const res = await postJson("/register", {
      email,
      password,
      fullName,
      termsAccepted: !!termsAccepted,
    });

    if (res.error) {
      toast.error(res.error);
    } else {
      login(res);
      onClose(res);
      const courseSlug = localStorage.getItem("courseSlug");
      if (courseSlug) {
        // Clear the stored courseSlug from local storage
        localStorage.removeItem("courseSlug");
        // Redirect to the courseSlug URL
        navigate(`/check-out/${courseSlug}`);
      } else {
        // Redirect to a default page after successful login
        navigate("/"); // Change this to the desired default URL
      }
    }
  };

  return (
    <div>
      <UIModalCloseButton className="!p-2.5" theme="light" onClick={onClose} />
      <div className="mt-3 px-5 pb-7 md:px-8 md:pb-8">
        <h2 className="text-center font-semibold text-[24px] mt-12">
          Creează cont nou
        </h2>

        <div className="text-center mb-8 mt-4">
          <span>Ai deja cont?</span>
          <span
            className="ml-1 link select-none text-blue cursor-pointer hover:underline"
            onClick={goToLogin}
          >
            Conectează-te aici!
          </span>
        </div>

        <Form onSubmit={onRegister}>
          <div className="flex flex-col">
            {/* <label>Email</label> */}
            <InputText
              type="text"
              name="fullName"
              placeHolder="Nume și Prenume"
              className="p-3 rounded-lg hover:ring-2 ring-third bg-primary  w-full focus:ring-2 focus:outline-none"
            ></InputText>
            <InputText
              type="email"
              name="email"
              placeHolder="Email"
              className="p-3 rounded-lg hover:ring-2 ring-third bg-primary  w-full focus:ring-2 focus:outline-none"
            ></InputText>
            <InputText
              type="password"
              name="password"
              placeHolder="Parolă"
              className="p-3 rounded-lg hover:ring-2 ring-third bg-primary  w-full focus:ring-2 focus:outline-none"
            ></InputText>
            <InputText
              type="password"
              name="confirmPass"
              placeHolder="Confirmă parola"
              className="p-3 rounded-lg hover:ring-2 ring-third bg-primary  w-full focus:ring-2 focus:outline-none"
            ></InputText>
          </div>

          <div className="mt-2 mb-12 text-md flex items-center">
            <input
              id="agree"
              type="checkbox"
              name="termsAccepted"
              className=""
            />
            <label htmlFor="agree" className="ml-1.5">
              Sunt de acord cu{" "}
              <Link to="/terms" className="link">
                Termeni și condiții
              </Link>
            </label>
          </div>

          <button
            type="submit"
            value="Submit"
            className="w-full rounded-lg bg-third text-white py-2 hover:bg-darker-third transition-all"
          >
            Înregistrează-te
          </button>
        </Form>

        {/* <div className="text-center my-4 text-secondary">
          sau conectează-te cu
        </div> */}
      </div>
    </div>
  );
}
