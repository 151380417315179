import { get, post, postJson, _deleteJson } from "./_methods";

export function checkLoginStatus() {
  return get("/user/login-status");
}

export function logoutUser() {
  return post("/logout");
}

export function populateCourseData() {
  return get(`/user/populate-courses`);
}

export function getUserAccess() {
  return get(`/user/plans`);
}
