import {
  RiUserLine,
  RiShoppingBasketLine,
  RiLogoutCircleRLine,
  RiSettings3Line,
  RiListCheck2,
  RiStarLine,
  RiDashboardLine,
  RiVideoLine,
} from "react-icons/ri";
import { formatUserPanelName } from "../../utils/format";

import { useContext, useState } from "react";

import UserContext from "../../context/UserContext";
import isAdmin from "../../utils/isAdmin";

export default function UserDropdown({
  openLoginPanel,
  openUserPanel,
  closePanels,
  navigateTo,
}) {
  const { user, logout } = useContext(UserContext);
  const [face, setFace] = useState(0);

  const onMyAccountClick = () => {
    if (user) {
      openUserPanel();
    } else {
      openLoginPanel();
    }
  };

  const onUserLogout = () => {
    closePanels();
    logout();
    navigateTo("/");
  };

  return (
    <li className="ml-1 list-none">
      <div
        className="rounded-full ring-2 ring-third px-6 pt-1 pb-1 hover:bg-third transition-all cursor-pointer"
        onClick={onMyAccountClick}
      >
        Contul meu
      </div>

      {/* User dropdown */}
      {user && (
        <div id="user-dropdown" className="popover right-0">
          <div className="p-3 text-xl text-start">{user.fullName}</div>
          {isAdmin(user) && (
            <div
              className="user-item"
              onClick={() => navigateTo("/admin/codes")}
            >
              <RiDashboardLine size={22} className="mr-2 text-third" />
              <span className="">Admin panel</span>
            </div>
          )}

          <div
            className="user-item"
            onClick={() => navigateTo("/user/courses")}
          >
            <RiVideoLine size={22} className="mr-2 text-third" />
            <span className="">Cursuri</span>
          </div>

          {/* <div
            className="user-item"
            onClick={() => navigateTo("/user/courses")}
          >
            <RiShoppingBasketLine size={22} className="mr-2 text-third" />
            <span className="">Istoric de plata</span>
          </div> */}

          <hr className="border-darker-gray my-1" />

          <div className="user-item" onClick={onUserLogout}>
            <RiLogoutCircleRLine size={22} className="mr-2 text-white" />
            <span className="">Deconectare</span>
          </div>
        </div>
      )}
    </li>
  );
}
