import React, { useEffect, useState, useRef } from 'react';

const ProgressLine = ({
  label,
  backgroundColor = '#e5e5e5',
  visualParts = [
    {
      percentage: '0%',
      color: 'white',
    },
  ],
}) => {
  const [widths, setWidths] = useState(
    visualParts.map(() => {
      return 0;
    })
  );

  const progressRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setWidths(visualParts.map((item) => item.percentage));
          entry.target.classList.add('visible');
        }
      });
    }, options);

    if (progressRef.current) {
      observer.observe(progressRef.current);
    }

    return () => {
      if (progressRef.current) {
        observer.unobserve(progressRef.current);
      }
    };
  }, [visualParts]);

  return (
    <>
      <div className="progressLabel">{label}</div>
      <div
        ref={progressRef}
        className="progressVisualFull"
        style={{
          backgroundColor,
        }}
      >
        {visualParts.map((item, index) => (
          <div
            key={index}
            style={{
              width: widths[index],
              backgroundColor: item.color,
            }}
            className="progressVisualPart"
          />
        ))}
      </div>
    </>
  );
};

export default ProgressLine;
