import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import usePrimaryBg from "../../hooks/usePrimaryBg";
import { Link } from "react-router-dom";
import useScrollToTop from "../../hooks/useScrollToTop";
import Button from "../../components/Button";
import Logo from "../../assets/images/logoFX.png";
import OwnProfile from "./OwnProfile";
import UserPanel from "../../components/UserPanel";
import Footer from "../../components/Footer";
import UserContext from "../../context/UserContext";
import { useContext } from "react";
import Navbar from "../../components/Navbar";

export default function User() {
  usePrimaryBg();
  const [title, setTitle] = useState("");
  const [active, setActive] = useState(""); // Initialize active state
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/user/courses")) {
      setActive("courses");
    } else {
      setActive("history");
    }
  }, [location.pathname]);

  const { user, logout } = useContext(UserContext);

  useScrollToTop([location]);

  return (
    <>
      <Navbar />
      <div className="container mb-10">
        <div className="">
          <UserPanel active={active} />
        </div>

        {/* Page content */}
        <div className="w-full overflow-hidden">
          <Outlet context={[setTitle]} />
        </div>
      </div>
      <Footer />
    </>
  );
}
