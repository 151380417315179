import React, { useState, useContext, useEffect } from 'react';

import Navbar from '../components/Navbar';
import usePrimaryBg from '../hooks/usePrimaryBg';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import useScrollToTop from '../hooks/useScrollToTop';
import Footer from '../components/Footer';
import Form from '../components/Form';
import { InputText } from '../components/Input';
import Logo from '../assets/images/logo_alb.png';
import UserContext from '../context/UserContext';
import { toast } from 'react-toastify';
import { checkCode, order } from '../api/orderApi';
import { getUserAccess } from '../api/userApi';
import { getPlan } from '../api/orderApi';

/**
 * This wrapper resets the component state when the product changes
 */
export default function ProductPage() {
  const { slug } = useParams();

  return <Checkout key={slug} />;
}

function Checkout() {
  usePrimaryBg();
  const navigate = useNavigate();
  const { user, checkSession } = useContext(UserContext);
  const [hasCourse, setHasCourse] = useState(false);
  const [hasScalping, setHasScalping] = useState(false);
  const [discountCode, setDiscountCode] = useState(''); // Add a state variable for discountCode
  const [plan, setPlan] = useState(null);
  const [discountedAmount, setDiscountedAmount] = useState(plan?.price);

  const location = useLocation();
  const { slug } = useParams();

  // Update the discountCode state when the input value changes
  const handleDiscountCodeChange = (e) => {
    setDiscountCode(e.target.value);
  };

  const applyDiscountCode = async () => {
    if (!discountCode) {
      return toast.error('Introdu codul.');
    }

    const res = await checkCode(discountCode);

    if (res.error) {
      toast.error(res.error);
      // Reset the discounted amount to the original price
      setDiscountedAmount(plan?.price);
    } else {
      const percentageDiscount = res.percentage; // Get the discount percentage from the API response
      const originalPrice = plan?.price; // Get the original price from plan
      const discountAmount = (percentageDiscount / 100) * originalPrice; // Calculate the discount amount

      // Calculate and set the discounted amount
      const newDiscountedAmount = originalPrice - discountAmount;
      setDiscountedAmount(newDiscountedAmount);

      toast.success(res.message);
    }
  };

  // fetch product
  useEffect(() => {
    async function fetchPlan() {
      const plan = await getPlan(slug);

      if (plan.error) {
        toast.error(plan.error);
        navigate('/home');
      } else {
        setPlan(plan);
        setDiscountedAmount(plan.price);
      }
    }
    fetchPlan();
  }, [slug]);

  useEffect(() => {
    async function fetchUserAccess() {
      try {
        const res = await getUserAccess(user); // Replace 'user' with the appropriate user ID or data

        if (res.error) {
          toast.error(res.error);
        } else {
          // Set the hasRequiredPlans state based on the response
          setHasCourse(res.course);
          // console.log(res.scalping);
          setHasScalping(res.scalping);
        }
      } catch (error) {
        console.error('Error fetching user access:', error);
        toast.error('An error occurred while fetching user access.');
      }
    }

    fetchUserAccess();
  }, [user]);

  useScrollToTop(location);

  const onOrder = async (formData) => {
    // const { fullName, email } = Object.fromEntries(formData);
    const { termsAccepted } = Object.fromEntries(formData);

    const planName = plan?.name;

    if (!termsAccepted) {
      return toast.error('Trebuie să fii de acord cu Termenii și Condițiile.');
    }

    const res = await order({ formData, planName });

    if (res.error) {
      toast.error(res.error);
    } else {
      if (res.stripeCheckoutUrl) {
        window.location = res.stripeCheckoutUrl;
      } else {
        toast.error('Eroare! Vă rugăm să ne contactați.');
      }
    }
  };

  return (
    <>
      <Navbar />

      <div className="container mt-4">
        <div className="bg-secondary flex flex-col justify-center text-center p-4 xl:p-20 rounded-xl items-center">
          {hasCourse &&
            plan?.slug !== 'sedinte' &&
            plan?.slug !== 'scalping' && (
              <>
                <div className="bg-red pt-4 pb-3 px-4 rounded-md mb-8">
                  Ai deja un plan achiziționat. Contactează-ne pentru achiziția
                  altui plan.
                </div>
              </>
            )}
          {hasScalping && plan?.slug === 'scalping' && (
            <>
              <div className="bg-red pt-4 pb-3 px-4 rounded-md mb-8">
                Ai achiziționat deja acest plan. Contactează-ne pentru achiziția
                altui plan.
              </div>
            </>
          )}
          <div className="text-3xl font-bold">Sumar comandă</div>
          <img src={Logo} className="w-60 mx-auto my-4" alt="logo" />
          <div className="text-3xl mb-8">
            <span className="font-bold">Plan:</span>{' '}
            <span id="product-name">{plan?.name}</span>
          </div>
          <Form className="lg:w-1/2" onSubmit={onOrder}>
            {/* Input fields */}
            <div className="flex flex-col lg:flex-row w-full">
              <InputText
                type="email"
                value={user?.email}
                required
                name="email"
                placeholder="Email"
                className="p-3 rounded-lg hover:ring-2 ring-third bg-primary  w-full focus:ring-2 focus:outline-none lg:mr-4"
              ></InputText>
              <InputText
                required
                type="tel"
                name="phone"
                placeHolder="Telefon"
                className="p-3 rounded-lg hover:ring-2 ring-third bg-primary focus:ring-2 focus:outline-none w-full "
              ></InputText>
            </div>

            <div className="flex">
              <InputText
                required
                type=""
                name="lastName"
                placeHolder="Nume"
                className="p-3 rounded-lg hover:ring-2 ring-third bg-primary focus:ring-2 focus:outline-none w-full mr-4"
              ></InputText>
              <InputText
                required
                type="text"
                name="firstName"
                placeHolder="Prenume"
                className="p-3 rounded-lg hover:ring-2 ring-third bg-primary focus:ring-2 focus:outline-none w-full"
              ></InputText>
            </div>
            <div className="flex">
              <InputText
                type="text"
                name="city"
                placeHolder="Oraș"
                className="p-3 rounded-lg hover:ring-2 ring-third bg-primary focus:ring-2 focus:outline-none w-full mr-4"
              ></InputText>
              <InputText
                type="text"
                name="county"
                placeHolder="Sector / Județ"
                className="p-3 rounded-lg hover:ring-2 ring-third bg-primary focus:ring-2 focus:outline-none w-full"
              ></InputText>
            </div>
            <InputText
              required
              type="text"
              name="address"
              placeHolder="Adresă"
              className="p-3 rounded-lg hover:ring-2 ring-third bg-primary w-full focus:ring-2 focus:outline-none"
            ></InputText>
            {plan?.slug === 'standard' && (
              <div className="flex items-center text-center">
                <InputText
                  type="text"
                  name="discountCode"
                  placeHolder="Cod de reducere (opțional)"
                  className="p-3 rounded-lg bg-primary w-full focus:ring-0 focus:outline-none rounded-r-none"
                  containerClassName="!mb-0"
                  value={discountCode}
                  onChange={handleDiscountCodeChange}
                ></InputText>
                <button
                  type="button"
                  className="p-3 bg-third rounded-r-xl h-full"
                  onClick={applyDiscountCode}
                >
                  Aplică
                </button>
              </div>
            )}

            {plan?.slug === 'scalping' && (
              <div className="flex items-center text-center">
                <InputText
                  type="text"
                  name="discountCode"
                  placeHolder="Cod de reducere (opțional)"
                  className="p-3 rounded-lg bg-primary w-full focus:ring-0 focus:outline-none rounded-r-none"
                  containerClassName="!mb-0"
                  value={discountCode}
                  onChange={handleDiscountCodeChange}
                ></InputText>
                <button
                  type="button"
                  className="p-3 bg-third rounded-r-xl h-full"
                  onClick={applyDiscountCode}
                >
                  Aplică
                </button>
              </div>
            )}
            <div className="text-end text-3xl my-4">
              <span className="font-bold">Total:</span>{' '}
              <span id="price">
                {discountedAmount !== plan?.price ? (
                  <span>
                    <span
                      className="original-price text-red"
                      style={{ textDecoration: 'line-through' }}
                    >
                      {plan?.price} EUR
                    </span>{' '}
                    {discountedAmount} EUR
                  </span>
                ) : (
                  <span>{discountedAmount} EUR</span>
                )}
              </span>
            </div>

            <div className="mt-2 mb-4 text-md flex items-center justify-end">
              <input
                id="agree"
                type="checkbox"
                name="termsAccepted"
                className=""
              />
              <label htmlFor="agree" className="ml-2 ">
                Sunt de acord cu{' '}
                <Link to="/terms" className="link">
                  Termeni și condiții
                </Link>
              </label>
            </div>

            <button
              type="submit"
              className={`w-full rounded-lg bg-third transition-all text-white py-2 mt-2 hover:bg-darker-third ${
                plan?.slug === 'scalping' ? 'cursor-not-allowed ' : ''
              }`}
              // disabled={plan?.slug === "scalping"}
            >
              Spre plată
            </button>
          </Form>
        </div>
      </div>

      <Footer />
    </>
  );
}
