const PROD_API = 'https://api.marketstructurefx.ro';
const LOCAL_API = `http://localhost:9000`;
// const LOCAL_API = `http://16.16.58.43:5000`;
// const TEST_API = "https://api";

function getAPI() {
  const host = window.location.hostname;

  if (host === 'localhost') return LOCAL_API;
  else return PROD_API;
}

export const API = getAPI();
