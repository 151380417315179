import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ADMIN } from 'marketfx-constants';

import Home from './routes/Home';
import UserDashboard from './routes/user';
import OwnProfile from './routes/user/OwnProfile';
import UserCourses from './routes/user/UserCourses';
import PlayCourse from './routes/user/PlayCourse';
import Checkout from './routes/Checkout';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from './routes/ProtectedRoute';
import OrderPlaced from './routes/OrderPlaced';
import Terms from './routes/terms/Terms';
import Privacy from './routes/terms/Privacy';
import AdminDashboard from './routes/admin';
import AdminCodes from './routes/admin/AdminCodes';
import Funnel from './routes/Funnel';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function App() {
  return (
    <>
      <div id="view">
        <Routes>
          <Route path="/" element={<Home />} />

          {/* User routes */}
          <Route
            path="/user"
            element={
              <ProtectedRoute>
                <UserDashboard />
              </ProtectedRoute>
            }
          >
            <Route element={<OwnProfile />} />
            <Route
              index
              path="courses"
              element={
                // <ProtectedRoute role={ARTIST}>
                <UserCourses />
                // </ProtectedRoute>
              }
            />

            <Route
              index
              path="courses/:courseSlug"
              element={
                // <ProtectedRoute role={ARTIST}>
                <PlayCourse />
                // </ProtectedRoute>
              }
            />

            {/* <Route path="profile" element={<OwnProfile />} />
          <Route path="profile/edit" element={<EditProfile />} /> */}
          </Route>
          <Route path="/order-placed" element={<OrderPlaced />} />

          <Route
            index
            path="/check-out/:slug"
            element={
              // <ProtectedRoute role="USER">
              <Checkout />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute role={ADMIN}>
                <AdminDashboard />
              </ProtectedRoute>
            }
          >
            <Route path="codes" index element={<AdminCodes />} />
          </Route>
          <Route path="/hftpassing" element={<Funnel />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
        <ToastContainer
          position="bottom-center"
          autoClose={2500}
          newestOnTop={false}
          closeOnClick
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </>
  );
}

export default App;
