export default function BenefitCard(props) {
  return (
    <>
      <div
        className={`${props.className} p-10 bg-secondary max-w-[80%] flex flex-col text-center items-center rounded-xl mx-auto mb-10 hover:ring-2 ring-third transition-all cursor-pointer`}
      >
        <div className="flex flex-col items-center">
          <div className="bg-[#2F2F37] p-4 rounded-full text-[39px]">
            <div className="">{props.icon}</div>
            {/* <img src={props.icon} className="" alt="Icon" /> */}
          </div>
          <div className="text-3xl mt-6 mb-4 font-bold">{props.title}</div>
        </div>
        <div className="text-xs xl:text-sm text-light-secondary">
          {props.text}
        </div>
      </div>
    </>
  );
}
