import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import CountUp from "react-countup";
import Collapsible from "../components/Collapsible";

import Logo from "../assets/images/logoFX.png";
import trophy from "../assets/images/trophy.png";
import Vector from "../assets/images/Untitled_design.svg";
import gradientBg from "../assets/images/slanted-gradient.svg";
import stockCharts from "../assets/images/Stock Chart.svg";
import shinyBg from "../assets/images/Wave.svg";
import animatedBg from "../assets/images/Animated Shape.svg";
import animatedMobile from "../assets/images/AnimatedMobile.svg";
import { useState, useEffect } from "react";
import Button from "../components/Button";

import Cert1 from "../assets/images/certs/1-min.png";
import Cert2 from "../assets/images/certs/2-min.png";
import Cert3 from "../assets/images/certs/3-min.png";
import Cert4 from "../assets/images/certs/4-min.png";
import Cert5 from "../assets/images/certs/5-min.png";
import Cert6 from "../assets/images/certs/6-min.png";
import Cert7 from "../assets/images/certs/7-min.png";
import Cert8 from "../assets/images/certs/8-min.png";
import Cert9 from "../assets/images/certs/9-min.png";
import Cert10 from "../assets/images/certs/10-min.png";
import Cert11 from "../assets/images/certs/11-min.png";
import Cert12 from "../assets/images/certs/12-min.png";
import Cert13 from "../assets/images/certs/13-min.png";
import Cert14 from "../assets/images/certs/14-min.png";
import Cert15 from "../assets/images/certs/15-min.png";
import Cert16 from "../assets/images/certs/16-min.png";
import Cert17 from "../assets/images/certs/17-min.png";

import KortanaLogo from "../assets/images/firms/kortana.png";
import InfinityLogo from "../assets/images/firms/infinity.png";
import NovaLogo from "../assets/images/firms/nova.png";

import { useCallback, useRef } from "react";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";

import * as Scroll from "react-scroll";

import ProgressLine from "../components/ProgressLine";

import { LinearGradient } from "react-text-gradients";

import {
  RiArrowRightSLine,
  RiArrowLeftSLine,
  RiArrowDownDoubleLine,
} from "react-icons/ri";

// Import Swiper styles

export default function Funnel() {
  const [isMobile, setIsMobile] = useState(false);
  let ScrollLink = Scroll.Link;

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleChange = (e) => {
      setIsMobile(e.matches);
    };

    mediaQuery.addListener(handleChange);

    return () => {
      mediaQuery.removeListener(handleChange);
    };
  }, []);

  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {}, []);

  const swiperRef1 = useRef(null);

  const slidesPerView = window.innerWidth <= 767 ? 1 : 2; // Adjust the breakpoint as needed

  return (
    <>
      <Navbar />
      <main className="relative overflow-x-hidden">
        <section className="bg-primary relative" name="home">
          <Particles
            canvasClassName="absolute top-0 left-0 h-full z-index-1"
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              background: {
                color: {
                  value: "bg-primary",
                },
              },
              fpsLimit: 60,
              interactivity: {
                events: {
                  onClick: {
                    enable: false,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                },
              },
              fullScreen: {
                enable: false,
                zIndex: 0,
              },

              particles: {
                color: {
                  value: "#ffffff",
                },
                links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 1,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 1400,
                  },
                  value: 80,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 5 },
                },
              },
              detectRetina: true,
            }}
          />
          <div className="container h-[91vh]  flex flex-col">
            <div className="flex flex-col lg:flex-row my-auto h-fit backdrop-blur-sm bg-white/4 border-1 border-white/20 rounded-md p-4 lg:p-20">
              <div className="flex flex-col justify-center text-center lg:text-left">
                <div className="font-bold  text-[25px] xl:text-[50px] z-index-2 relative">
                  <LinearGradient gradient={["to left", "#0066FF,#00B8FF"]}>
                    Îți trecem conturile de FINANȚARE
                  </LinearGradient>
                  <div className="text-[35px] xl:text-[70px]">
                    <LinearGradient gradient={["to left", "#00FF47,#00B8FF"]}>
                      100% GRATUIT{" "}
                    </LinearGradient>
                  </div>
                </div>
                <img
                  src={Vector}
                  className="mx-auto mt-4 w-[300px] lg:hidden"
                  alt="logo"
                />
                <div className="lg:mt-20 lg:pr-20">
                  Suntem hotărâți să sprijinim 10.000 de traderi români pentru a
                  accesa capital de tranzacționare oferit de firmele de
                  finanțare. Este șansa ta de a-ți diversifica portofoliul.
                </div>

                <ScrollLink to="target" smooth={true} offset={0}>
                  <Button
                    text="Rezultate"
                    icon={RiArrowRightSLine}
                    iconRight={RiArrowRightSLine}
                    containerClassName="mt-8 lg:mt-20 text-content w-fit animate-bounce"
                    caret
                  />
                </ScrollLink>
              </div>
              <div className="flex items-center relative">
                <img
                  src={Vector}
                  className="my-auto w-[500px] hidden lg:block"
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-grey-charts bg-primary py-20" name="target">
          <div className="container">
            <div className="text-center text-4xl lg:text-8xl font-bold relative z-10 mb-12">
              Rezultate obținute
            </div>
            <div className="backdrop-blur-sm bg-white/5 border-1 border-white/20 rounded-md py-10 drop-shadow-xl">
              <div className="absolute left-0 lg:left-[30%] blur-3xl top-0 bg-third/50 w-[500px] h-[500px] rounded-full"></div>
              <div className="absolute right-0 lg:right-[30%] blur-3xl bottom-0 bg-[#00FFFF]/50 w-[500px] h-[500px] rounded-full"></div>
              <div className="flex justify-center flex-col items-center">
                <div className="w-[120px] relative mb-8">
                  <img
                    src={trophy}
                    className="my-auto w-full drop-shadow-lg"
                    alt="logo"
                  />
                </div>
                <div className="relative w-fit max-w-full text-center">
                  <CountUp
                    start={0}
                    end={21450000}
                    enableScrollSpy
                    scrollSpyDelay={500}
                    duration={4}
                    scrollSpyOnce
                  >
                    {({ countUpRef }) => (
                      <div className="text-8xl font-bold drop-shadow-lg">
                        <span ref={countUpRef} />$
                      </div>
                    )}
                  </CountUp>

                  <div className="relative">
                    <ProgressLine
                      className=""
                      backgroundColor="#0f0f12"
                      visualParts={[
                        {
                          percentage: "75%",
                          color: "#4816ea",
                        },
                      ]}
                    ></ProgressLine>
                    <div className="absolute -top-0.5 right-3 text-lg">30M</div>
                  </div>

                  <CountUp
                    start={0}
                    end={570}
                    enableScrollSpy
                    scrollSpyDelay={500}
                    duration={4}
                    scrollSpyOnce
                  >
                    {({ countUpRef }) => (
                      <div className="text-6xl font-bold drop-shadow-md">
                        <span ref={countUpRef} /> CONTURI
                      </div>
                    )}
                  </CountUp>

                  <div className="text-2xl font-bold">FINANȚATE PÂNĂ ACUM</div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full relative mb-8 flex justify-center text-center items-center mt-8">
            <img
              src={Logo}
              className="my-auto mx-auto  drop-shadow-sm w-[240px]"
              alt="logo"
            />
          </div>
        </section>

        <section className="bg-secondary py-20 relative" name="certs">
          <img
            src={shinyBg}
            className="absolute top-0 left-0 h-full w-full"
            alt="logo"
          />{" "}
          <div className="container">
            <div className="text-center text-4xl lg:text-8xl font-bold relative z-10 mb-12">
              Certificări
            </div>
            <div className="relative">
              <div
                className="swiper-prev absolute top-1/2 -left-14"
                onClick={() => swiperRef1.current?.slidePrev()}
              >
                <RiArrowLeftSLine className="text-8xl" />
              </div>
              <div
                className="swiper-next absolute top-1/2 -right-14"
                onClick={() => swiperRef1.current?.slideNext()}
              >
                <RiArrowRightSLine className="text-8xl" />
              </div>
              <div className="">
                <Swiper
                  slidesPerView={slidesPerView}
                  spaceBetween={30}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper swiper-certificates" // Unique class name
                  onSwiper={(swiper) => (swiperRef1.current = swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <img src={Cert1} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert2} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert3} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert4} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert5} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert6} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert7} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert8} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert9} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert10} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert11} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert12} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert13} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert14} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert15} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert16} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src={Cert17} alt="cert1" className="w-full"></img>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        <div className="relative">
          <img
            src={isMobile ? animatedMobile : animatedBg}
            className="absolute top-0 left-0 h-full w-full"
          />
          <section className="bg-secondary pt-4 pb-8">
            <div className="container relative">
              <div className="text-center text-4xl lg:text-8xl font-bold  z-10 mb-12">
                Misiunea noastră
              </div>
              <div className="text-center text-lighter-gray">
                La <span className="text-white">Market Structure FX</span>,
                credem cu tărie că fiecare trader român merită oportunitatea de
                a avea acces la un capital de tranzactionare si sansa de a-si
                demonstra abilitatile. Vrem sa ajutam in mod{" "}
                <span className="text-green">gratuit</span>{" "}
                <span className="underline">10.000 de traderi romani</span>,
                prin trecerea conturilor de finanțare. Acesta este angajamentul
                nostru față de fiecare trader român. Suntem aici pentru a face
                tranzacționarea mai accesibilă și orientată către succesul tău.
              </div>
            </div>
          </section>
          <section className="bg-secondary pt-4 pb-8">
            <div className="container relative">
              <div className="text-center text-4xl lg:text-8xl font-bold  z-10 mb-12">
                Ce reprezinta HFT?
              </div>
              <div className="text-center text-lighter-gray ">
                <span className="text-white">High-Frequency Trading (HFT)</span>
                , în traducere "tranzacționare de înaltă frecvență", este o
                strategie de tranzacționare automatizată care implică efectuarea
                unui mare număr de tranzacții într-un interval de timp foarte
                scurt. Această modalitate de tranzacționare se definește prin
                utilizarea algoritmilor avansați, care contribuie la procesul
                rapid și eficient de trecere a conturilor de finanțare într-un
                interval de timp extrem de scurt.
              </div>
            </div>
          </section>
          <section className="bg-secondary pt-4 pb-8">
            <div className="container relative">
              <div className="text-center text-4xl lg:text-8xl font-bold  z-10 mb-12">
                Ce este o firmă de finanțare?
              </div>
              <div className="text-center text-lighter-gray">
                O firma de finantare reprezintă o entitate care pune la
                dispoziția traderilor propriul său capital pentru a gestiona
                tranzacții pe piata Forex. Principalul avantaj al unei firme de
                finanțare este că traderii pot beneficia de capital suplimentar
                pentru tranzacționare, ceea ce le permite să exploreze și să
                abordeze piețele financiare cu un{" "}
                <span className="underline">potențial mai mare</span> de
                <span className="text-green"> profitabilitate</span> decât ar
                avea tranzacționând doar cu propriul lor capital. În plus,
                firmelor de finanțare le poate reveni și o parte din profiturile
                realizate de traderii lor, ceea ce poarta denumirea de "Profit
                Split".
              </div>
            </div>
          </section>
          <section className="bg-secondary pt-4 pb-8">
            <div className="container relative">
              <div className="text-center text-4xl lg:text-8xl font-bold  z-10 mb-12">
                Cu ce te putem ajuta noi?
              </div>
              <div className="text-center text-lighter-gray">
                Venim in ajutorul tau cu robotul nostru de tranzactionare (HFT)
                pentru a iti trece conturile de finantare pe{" "}
                <span className="text-green">LIVE</span> în{" "}
                <span className="underline">maxim 24-48 ore</span>. Verifica
                lista firmelor de finantare ce accepta trecerea fazelor de
                testare in baza botului de HFT pe care il detinem noi.
              </div>
            </div>
          </section>
          <section className="bg-secondary pt-4 pb-8">
            <div className="container relative ">
              <div className="text-center text-4xl lg:text-8xl font-bold  z-10 mb-12">
                Prop Firms Acceptate
              </div>
              <div className="text-center mb-12 text-lighter-gray">
                <span className="text-red">Nu</span> avem posibilitatea sa
                trecem conturile la alte firme, decat la{" "}
                <span className="underline">acestea</span>. Nu ne asumam nicio
                raspundere in cazul in care ati cumparat un cont la o alta
                firma.
              </div>
              <div className="flex justify-around">
                <Link
                  to="https://kortanafx.com/?linkId=lp_737338&sourceId=msfxgroup&tenantId=kortanafx"
                  target="_blank"
                  className="w-1/4"
                >
                  <img
                    src={KortanaLogo}
                    className="w-full rounded-full  hover:scale-110  transition-all cursor-pointer"
                    alt="kortana-logo"
                  />
                </Link>

                <Link
                  to="https://trading.infinityforexfunds.com/#a_aid=6599c62f68c8a"
                  target="_blank"
                  className="w-1/4"
                >
                  <img
                    src={InfinityLogo}
                    className="w-full rounded-full  hover:scale-110 transition-all cursor-pointer"
                    alt="infinity-logo"
                  />
                </Link>
                <Link
                  to="https://nova-funding.com/"
                  target="_blank"
                  className="w-1/4"
                >
                  <img
                    src={NovaLogo}
                    className="w-full rounded-full hover:scale-110 transition-all  cursor-pointer"
                    alt="nova-logo"
                  />
                </Link>
              </div>
              <div className="tracking-wider text-[15px] text-center mt-12">
                <div className="text-lighter-gray mb-2">
                  Te putem ajuta și la aceste firme
                </div>
                <div>
                  MSOLUTIONS <span className="text-lighter-gray">︱</span> FAST
                  FOREX FUNDING <span className="text-lighter-gray">︱</span>{" "}
                  SOCIAL TRADING CLUB{" "}
                  <span className="text-lighter-gray">︱</span> QUANTEC TRADING{" "}
                  <span className="text-lighter-gray">︱</span> NEXT STEP FUNDED
                </div>
              </div>
            </div>
          </section>
          <section className="bg-gradient-bg py-20 relative" name="faq">
            <div className="w-full">
              <img
                src={gradientBg}
                className="absolute top-0 left-0 h-full w-full"
                alt="background"
              />
            </div>
            <div className="container relative">
              <div className="text-center text-4xl lg:text-8xl font-bold relative z-10 mb-12">
                Cum funcționează?
              </div>
              <div className="flex flex-col lg:flex-row">
                <div className="lg:w-1/3 backdrop-blur-sm bg-white/5 border-1 border-white/20 rounded-md p-10 drop-shadow-xl">
                  <div className="font-bold text-4xl mb-4 ">
                    Îți alegi contul dorit
                  </div>
                  <span className="text-lighter-gray">
                    Alegi valoarea contului pe care dorești să îl achiziționezi
                    și firma de finanțare cu care îți dorești să lucrezi,
                    desigur, după ce ți-ai făcut un research amănunțit al
                    acesteia.
                  </span>
                </div>
                <RiArrowRightSLine className="text-8xl relative items-center text-center my-auto mx-2  hidden lg:block" />
                <RiArrowDownDoubleLine className="text-8xl relative items-center text-center mx-auto my-4 lg:hidden" />
                <div className="lg:w-1/3 backdrop-blur-sm bg-white/5 border-1 border-white/20 rounded-md p-10 drop-shadow-xl">
                  <div className="font-bold text-4xl mb-4 ">
                    Trimiți datele contului tau
                  </div>
                  <span className="text-lighter-gray">
                    Poți trimite datele contului tău pe{" "}
                    <Link
                      to="https://www.instagram.com/marketstructurefx.ro/"
                      target="_blank"
                      className="text-white hover:underline"
                    >
                      Instagram
                    </Link>
                    ,{" "}
                    <Link
                      to="https://discord.gg/ab9TQzXgAN"
                      target="_blank"
                      className="text-blue hover:underline"
                    >
                      {" "}
                      Discord
                    </Link>{" "}
                    sau pe adresa noastră de email{" "}
                    <a
                      href="mailto:freepass@marketstructurefx.ro"
                      className="hover:underline"
                    >
                      freepass@marketstructurefx.ro
                    </a>
                  </span>
                </div>
                <RiArrowRightSLine className="text-8xl relative items-center text-center my-auto mx-2  hidden lg:block" />
                <RiArrowDownDoubleLine className="text-8xl relative items-center text-center mx-auto my-4 lg:hidden" />
                <div className="lg:w-1/3 backdrop-blur-sm bg-white/5 border-1 border-white/20 rounded-md p-10 drop-shadow-xl">
                  <div className="font-bold text-4xl mb-4 ">
                    Îți trecem contul în faza LIVE
                  </div>
                  <span className="text-lighter-gray">
                    Îți trecem faza de testare, iar compania de la care ai
                    achiziționat contul o să îți ofere contul LIVE.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="bg-primary relative">
          <img
            src={stockCharts}
            className="absolute top-0 left-0 h-full w-full"
            alt="background"
          />
          <div className="container py-8">
            <div className="text-center text-8xl font-bold relative z-10 mb-12">
              FAQ
            </div>
            <div className="text-center mb-6 text-lighter-gray relative">
              În cazul în care nu ești lămurit, te rog să ne adresezi
              întrebările tale pe adresa de email{" "}
              <a
                href="mailto:freepass@marketstructurefx.ro"
                className="hover:underline"
              >
                freepass@marketstructurefx.ro
              </a>
            </div>
            <Collapsible
              title={
                "De ce Market Structure FX oferă trecerea gratuită a conturilor de finanțare?"
              }
              containerClassName="fade-in w-full"
            >
              <div className="w-full h-auto">
                Market Structure FX furnizează trecerea gratuită a conturilor de
                finanțare ca parte a angajamentului nostru de a sprijini și
                dezvolta comunitatea de traderi români. Scopul nostru este de a
                facilita accesul la conturi de finantare și de a contribui la
                creșterea nivelului de succes în tranzacționare pentru membrii
                comunității. Această inițiativă reflectă viziunea noastră de a
                oferi suport și resurse pentru îmbunătățirea experienței
                dumneavoastră în lumea tranzacționării.
              </div>
            </Collapsible>
            <Collapsible
              title={"Ce garantii ofera serviciul vostru?"}
              containerClassName="fade-in w-full"
            >
              <div className="w-full h-auto">
                Suntem extrem de încrezători în soluția noastră, astfel încât,
                în eventualitatea în care contul întâmpină probleme din cauza
                noastră, vom restitui integral suma plătită de dumneavoastra
                pentru achizitionarea contului.
              </div>
            </Collapsible>
            <Collapsible
              title={
                "Market Structure FX va avea acces la contul meu LIVE după finalizarea testelor?"
              }
              containerClassName="fade-in w-full"
            >
              <div className="w-full h-auto">
                După trecerea cu succes a fazelor de testare de către echipa
                noastră, vei primi datele contului LIVE exclusiv prin
                intermediul adresei tale de e-mail personală. Te asigurăm că nu
                vom avea acces niciodată la contul tău LIVE.
              </div>
            </Collapsible>
            <Collapsible
              title={
                "Care este motivul pentru care trebuie să va trimit detaliile contului?"
              }
              containerClassName="fade-in w-full"
            >
              <div className="w-full h-auto">
                Pentru a realiza serviciul convenit, avem nevoie de informațiile
                contului tău. Acestea ne permit să parcurgem cu succes procesele
                de finanțare și să ajungem la stadiul LIVE.
              </div>
            </Collapsible>
            <Collapsible
              title={"Putem cumpara robotul de HFT de la voi? "}
              containerClassName="fade-in w-full"
            >
              <div className="w-full h-auto">
                NU. Market Structure FX nu vinde roboti de High Frequency
                Trading si NU INCASEAZA nicio suma de bani pentru trecerea
                conturilor de finantare.
              </div>
            </Collapsible>
            <Collapsible
              title={
                "Cât durează până când contul meu va trece cu succes prin fazele de testare și voi primi accesul la varianta LIVE?"
              }
              containerClassName="fade-in w-full"
            >
              <div className="w-full h-auto">
                O zi este durata medie din momentul in care primim datele de
                conectare, contul tău va trece cu succes prin fazele de testare,
                iar accesul la versiunea LIVE va fi disponibil. Totuși, trebuie
                să subliniem că momentul efectiv al obținerii accesului la
                contul LIVE poate varia în funcție de verificările specifice ale
                firmei de finanțare alese de dumneavoastra, fără ca noi să avem
                control direct asupra acestui aspect.
              </div>
            </Collapsible>
            <Collapsible
              title={
                "Pe ce platforma trebuie sa fie contul? MetaTrader 4 sau MetaTrader 5?"
              }
              containerClassName="fade-in w-full"
            >
              <div className="w-full h-auto">
                Putem procesa EXCLUSIV conturi de MetaTrader 4. Nu ne asumăm
                responsabilitatea în situația în care achiziționați un cont de
                MetaTrader 5.
              </div>
            </Collapsible>
            <Collapsible
              title={
                "Exista firme in lista specificata de voi care furnizeaza si challenge-uri unde scrie ca HFT nu este permis, pe acelea le puteti trece?"
              }
              containerClassName="fade-in w-full"
            >
              <div className="w-full h-auto">
                Acceptăm și procesăm doar conturile achiziționate din secțiunea
                HFT ALLOWED (High-Frequency Trading PERMIS). Nu ne asumăm
                responsabilitatea pentru conturile trimise care nu provin din
                această secțiune specifică de High Frequency Trading.
              </div>
            </Collapsible>
            <Collapsible
              title={
                "Ce se întâmplă în eventualitatea în care compania de finanțare la care am contul decide să înceteze activitatea?"
              }
              containerClassName="fade-in w-full"
            >
              <div className="w-full h-auto">
                Nu avem nicio putere sau impact asupra acestui subiect. Domeniul
                firmelor de finanțare este încă în proces de reglementare, iar,
                cu toate că există incertitudini, șansele ca aceste companii să
                își înceteze activitatea sunt reduse.
              </div>
            </Collapsible>
            <Collapsible
              title={
                "Care este rata de trecere a conturilor de finantare pana acum?"
              }
              containerClassName="fade-in w-full"
            >
              <div className="w-full h-auto">
                Până acum, toate conturile de finanțare încredințate serviciului
                nostru au înregistrat o rată de trecere de 100%.
              </div>
            </Collapsible>
            <Collapsible
              title={
                "Ma pot conecta pe cont de pe IP-ul meu? Puteti trece conturi pe care deja am tranzactionat?"
              }
              containerClassName="fade-in w-full"
            >
              <div className="w-full h-auto">
                Vă aducem la cunoștință că, în conformitate cu politicile
                noastre, nu acceptăm conturi care au fost anterior conectate sau
                pe care s-au efectuat tranzacții în trecut. Preferăm conturile
                considerate 'fresh', adică care nu au fost utilizate anterior.
                Menționăm că majoritatea instituțiilor financiare nu permit
                accesul simultan de pe mai multe adrese IP, existând riscul
                închiderii conturilor fără nicio implicare din partea noastră.
                Prin urmare, vă solicităm respectuos să evitați conectarea la
                conturile demo de pe propriul dvs. IP.
              </div>
            </Collapsible>
            <Collapsible
              title={
                "Puteți să puneți botul de HFT și pe conturile noastre LIVE? Oferiți servicii de Account Management?"
              }
              containerClassName="fade-in w-full"
            >
              <div className="w-full h-auto">
                Vă apreciem interesul pentru serviciile noastre. Regretăm să vă
                informăm că nu putem implementa botul de High-Frequency Trading
                (HFT) pe conturile LIVE. Această opțiune este disponibilă doar
                pe conturile de testare, conform reglementărilor impuse în
                industrie. În plus, vrem să subliniem că nu oferim servicii de
                account management.
              </div>
            </Collapsible>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
