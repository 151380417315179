// import Button from "components/Button";
// import StatusIcon from "components/StatusIcon";

import useScrollToTop from "../hooks/useScrollToTop";
import UserContext from "../context/UserContext";
// import { Status } from "custom-studio-constants";
import { useContext, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import usePrimaryBg from "../hooks/usePrimaryBg";
import { RiCheckboxCircleLine, RiArrowRightSLine } from "react-icons/ri";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function OrderPlaced() {
  const { user } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const cancelled = searchParams.get("cancelled") === "true";

  usePrimaryBg();
  useScrollToTop();

  return (
    <>
      <Navbar></Navbar>
      <div className="container flex justify-center flex-col items-center my-auto min-h-[60vh]">
        {cancelled ? (
          <>
            <h3 className="mt-2 font-semibold">Plata eșuată.</h3>
            <p className="mt-2 mb-8">
              Comanda aceasta va fi anulată, dar poți plasa una nouă.
            </p>
          </>
        ) : (
          <>
            <RiCheckboxCircleLine className="text-[50px] mb-6 text-green" />
            <h3 className="mt-2 font-semibold text-3xl">
              Comanda a fost plasată!
            </h3>

            <p className="mt-4 mb-8"></p>
          </>
        )}
        {user && (
          <Link
            to="/user/courses"
            className="flex hover:text-third transition-all items-center"
          >
            <span className="">Cursurile mele</span>
            <RiArrowRightSLine />
          </Link>
        )}
      </div>
      <Footer></Footer>
    </>
  );
}
