import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import usePageTitle from "./usePageTitle";

export default function useOutletTitle(title) {
  const [setTitle] = useOutletContext();
  usePageTitle(title + " - Market Structure");

  useEffect(() => {
    setTitle(title);
  }, [title]);
}
