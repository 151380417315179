import React, { useState } from "react";

function findAndModifySubmitButton(children, loading) {
  return React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.props.type === "submit") {
        return React.cloneElement(child, { loading });
      } else if (child.props.children && child.props.children.length > 1) {
        return React.cloneElement(
          child,
          {},
          ...findAndModifySubmitButton(child.props.children, loading)
        );
      }
    }
    return child;
  });
}

export default function Form({ children, onSubmit, className = "" }) {
  const [loading, setLoading] = useState(false);

  const onSubmitProxy = async (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    await onSubmit(new FormData(e.target), e);
    setLoading(false);
  };

  return (
    <form onSubmit={onSubmitProxy} className={className}>
      {findAndModifySubmitButton(children, loading)}
    </form>
  );
}
